import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { calculateBMIpatient, calculateDifference, getColorAndCategory, getCorrectDate, getFormattedDateForInput, getUserInformation, numberLimter, preventInvalidNumberInput } from '../../../../../../../common'
import { useState } from 'react';
import { ApiCall_GET, ApiCall_POST } from '../../../../../../../Redux/Generic/action';
import Swal from 'sweetalert2';
import Draggable from 'react-draggable';
import PinModal from '../../../../../../Components/PinModal';
import { useAppContext } from '../../../../../../../AppContextProvider ';

export const NursingCheckList = ({ ApiCall_POST, artId, rowData }) => {

    console.log(artId, "artId");
    console.log(rowData, "rowData");

    // rowData = { rowData } artId = { artId } task = { task }
    const [formData, setFormData] = useState({
        doner: rowData.DonorRecipientOptionProcedureId === 161 ? 2 : rowData.DonorRecipientOptionProcedureId === 169 ? 3 : 1
    });

    const [ShowModal, setShowModal] = useState(false)
    const theme = useAppContext().themeDetails;

    // const handleInputChange = (e) => {
    //     const { id, name, value, checked, type } = e.target;

    //     if (type === 'checkbox') {

    //         setFormData(prevFormData => ({
    //             ...prevFormData,
    //             [name]: checked ? true : false,
    //         }));
    //     }
    //     if else(type === 'radio') {
    //         setFormData({
    //             ...formData,
    //             [name]: value,
    //         });
    //     }
    // else {
    //         setFormData({
    //             ...formData,
    //             [name]: value,
    //         });
    //     }

    // };

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;

        if (name === 'partnerHeight') {
            let bmi = calculateBMIpatient(value, formData.partnerWeight);
            setFormData((prevData) => ({
                ...prevData,
                ['partnerBMI']: bmi,
            }));
        }
        if (name === 'partnerWeight') {
            let bmi = calculateBMIpatient(formData.partnerHeight, value);
            setFormData((prevData) => ({
                ...prevData,
                ['partnerBMI']: bmi,
            }));
        }
        if (name === 'patientHeight') {
            let bmi = calculateBMIpatient(value, formData.patientWeight);
            setFormData((prevData) => ({
                ...prevData,
                ['patientBMI']: bmi,
            }));
        }
        if (name === 'patientWeight') {
            let bmi = calculateBMIpatient(formData.patientHeight, value);
            setFormData((prevData) => ({
                ...prevData,
                ['patientBMI']: bmi,
            }));
        }


        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: type === 'checkbox'
                ? checked
                : type === 'radio'
                    ? Number(value)
                    : value,
        }));
    };

    console.log(formData, "formData");

    useEffect(() => {

        // ApiCall_GET('UserManagment/GetClinicianList', true).then(function (payload) {
        //     setClinicianlist(payload);
        // });

    }, [])

    const saveNursingCheckList = () => {

        // ApiCall_POST('Art/SaveBloodTestDetails', formData, true).then(function (payload) {
        //     setShowModal(false)
        // });
    }



    // const handlePinChange --- check for later
    const patientData = getColorAndCategory(calculateBMIpatient(formData.patientHeight, formData.patientWeight));
    const partnerData = getColorAndCategory(calculateBMIpatient(formData.partnerHeight, formData.partnerWeight));

    return (
        <div onDoubleClick={(e) => e.stopPropagation()} className={" rounded  p-2 my-1 w-[99%] m-auto bg-white shadow border"}>

            <div className='flex items-center gap-x-2 pb-1 '>
                <label className={`mr-4 font-semibold border-b-[3px] border-b-amber-500 cursor-pointer ${theme.txt_color} `}>Nursing Cycle CheckList</label>
                <input name='ptreatment' defaultValue={formData?.ptreatment} type='checkbox' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + ' shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                {/* <label className={theme.txt_color + "  text-sm text-gray-700 font-semibold "} ><i>Mark Foley ~ 7-MAR-1978 </i></label> */}

                {formData.ptreatment === true && <label className="text-xss ">
                    ~  by{" "}{/* {item.AddedByName ? ( */}
                    <i><span className="text-gray-700 font-bold">{/* {item.AddedByName} */}Mark Foley</span></i>
                    on ~
                    <i><span className="text-gray-700 font-bold">7-MAR-1978{" "}</span></i>
                    {/* <i><span className="text-gray-700 font-bold">{formatTime(item.addedDate, true)}</span></i>{" "} */}
                </label>}

            </div>


            <div className="flex items-center gap-x-2 p-1 mb-2 border rounded bg-gray-100">
                <div className="flex items-center gap-x-2 px-2">
                    <input
                        type="radio"
                        id="nonDoner"
                        checked={formData.doner === 1}
                        disabled={formData.doner !== 1}
                        onChange={(e) => { handleInputChange(e) }}
                        value="1"
                        name="doner"
                        className={`${theme.cheqColor} px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none`}
                    />
                    <label className={`${theme.txt_color} text-sm font-bold`}>Non Donor</label>
                </div>
                <div className="flex items-center gap-x-2 px-2">
                    <input
                        type="radio"
                        id="donerRecipient"
                        checked={formData.doner === 2}
                        disabled={formData.doner !== 2}
                        onChange={(e) => { handleInputChange(e) }}
                        value="2"
                        name="doner"
                        className={`${theme.cheqColor} px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none`}
                    />
                    <label className={`${theme.txt_color} text-sm font-bold`}>Donor Recipient</label>
                </div>
                <div className="flex items-center gap-x-2 px-2">
                    <input
                        type="radio"
                        id="eggDoner"
                        checked={formData.doner === 3}
                        disabled={formData.doner !== 3}
                        onChange={(e) => { handleInputChange(e) }}
                        value="3"
                        name="doner"
                        className={`${theme.cheqColor} px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none`}
                    />
                    <label className={`${theme.txt_color} text-sm font-bold`}>Egg Donor</label>
                </div>
            </div>


            <div className={theme.panelBg + ' flex   rounded'}>
                <div className='  w-3/12 px-2'>
                    <div className={' flex items-center'}>
                        <input name='nurseEducationSession' defaultValue={formData?.nurseEducationSession} type='checkbox' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                        <p className={" text-sm mx-2  w-36"} >Nurse Education Session</p>
                    </div>
                    <div className={'pt-1 flex items-center'}>
                        <label htmlFor="cycleLength" className={" text-sm mx-2 w-36  "} >Cycle Length</label>
                        <input type="text" value={formData?.cycleLength} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                    </div>
                    <div className={'pt-1 flex flex-col'}>
                        <label className={" text-sm font-semibold  w-36 "} >Counselling (Vic Only)</label>
                        <div className='flex gap-x-2 px-2'>
                            <input name='clearedTreatment' defaultValue={formData?.clearedTreatment} type='checkbox' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '    rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                            <label className={" text-sm "} >Cleared for Treatment</label>
                        </div>
                    </div>
                    <div className={'w-full text-left py-0.5 pt-1 ' + theme.hoverBg}>
                        <p className='w-full  text-sm font-semibold  text-gray-900'>Pathology Results Completed ({'<'}2yrs old)</p>
                        {formData.doner === 1 && <div className="px-2 justify- gap-x-2 items-center ">
                            <div className='rounded mb-0.5 w-1/2  flex items-center justify-'>
                                <input
                                    type='checkbox'
                                    checked={formData.femaleScreeningblood}
                                    onChange={(e) => handleInputChange(e)}
                                    className={theme.cheqColor + ' border focus:outline-none'}
                                    name='femaleScreeningblood'
                                    value="false" />
                                <label htmlFor="femaleScreeningblood" className={" text-sm px-2 "} >Female Screening blood</label>
                            </div>
                            <div className='rounded w-1/2  flex items-center'>
                                <input
                                    type='checkbox'
                                    checked={formData.partherScreeningBlood}
                                    onChange={(e) => handleInputChange(e)}
                                    className={theme.cheqColor + ' border focus:outline-none'}
                                    name='partherScreeningBlood'
                                />
                                <label htmlFor="partherScreeningBlood" className="ml-2 text-sm text-gray-800">Parther Screening blood</label>
                            </div>
                        </div>}

                        {formData.doner === 2 && <div className="px-2 justify- gap-x-2 items-center ">
                            <label className={" text-sm font-semibold  w-36 "} >Known</label>

                            <div className='rounded mb-0.5 w-1/2  flex items-center justify-'>
                                <input
                                    type='checkbox'
                                    checked={formData.sperm}
                                    onChange={(e) => handleInputChange(e)}
                                    className={theme.cheqColor + ' border focus:outline-none'}
                                    name='sperm'
                                    value="false" />
                                <label htmlFor="sperm" className={" text-sm px-2 "} >Sperm</label>
                            </div>
                            <div className='rounded w-1/2  flex items-center'>
                                <input
                                    type='checkbox'
                                    checked={formData.egg}
                                    onChange={(e) => handleInputChange(e)}
                                    className={theme.cheqColor + ' border focus:outline-none'}
                                    name='egg'
                                />
                                <label htmlFor="egg" className="ml-2 text-sm text-gray-800">Egg</label>
                            </div>
                            <div className='rounded w-1/2  flex items-center'>
                                <input
                                    type='checkbox'
                                    checked={formData.embryo}
                                    onChange={(e) => handleInputChange(e)}
                                    className={theme.cheqColor + ' border focus:outline-none'}
                                    name='embryo'
                                />
                                <label htmlFor="embryo" className="ml-2 text-sm text-gray-800">Embryo</label>
                            </div>
                        </div>}
                        {formData.doner === 3 && <div className="px-2 justify- gap-x-2 items-center ">

                            <div className='rounded mb-0.5 w-1/2  flex items-center justify-'>
                                <input
                                    type='checkbox'
                                    checked={formData.knownOnly}
                                    onChange={(e) => handleInputChange(e)}
                                    className={theme.cheqColor + ' border focus:outline-none'}
                                    name='knownOnly'
                                    value="false" />
                                <label htmlFor="knownOnly" className={" text-sm px-2 "} >Known Only</label>
                            </div>


                        </div>}
                    </div>
                </div>
                <div className=' w-4/12 '>

                    {formData.doner === 1 && <div className='shadow'>
                        <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                            <p className='text-sbase font-medium'>Pathology Results / Status</p>
                        </div>
                        <div className='flex w-full'>
                            <div className={'justify-between text-left px-2  pt-0  w-1/2 border-r-[2px]  border-r-gray-200    ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase py-1 font-bold w-44 text-gray-700'>Patient</p>

                                <div className='flex pb-1 '>
                                    <p className='cursor-pointer py-1 px-2 border w-1/2 text-sbase  text-gray-700'>Status  </p>
                                    <p className='cursor-pointer py-1 px-2 border w-1/2 text-sbase font-bold  text-green-600'>completed</p>
                                </div>
                            </div>
                            <div className={'justify-between text-left  px-2 pt-0  w-1/2  border-r-[2px]  border-r-gray-200    ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase  py-1 font-bold w-44 text-gray-700'>Partner</p>

                                <div className='flex pb-1 '>
                                    <p className='cursor-pointer w-1/2 border py-1 px-2 text-sbase  text-gray-700'>Status  </p>
                                    <p className='cursor-pointer w-1/2 border py-1 px-2 text-sbase font-bold  text-green-600'>completed</p>
                                </div>
                            </div>
                        </div>
                    </div>}

                    {formData.doner === 2 &&
                        <div className={'justify-between text-left  pt-0  w-full border-r-[2px]  border-r-gray-200    '}>
                            <div className={theme.tHedUpdte + ' flex text-white text-left p-1 rounded-t'}>
                                <div className='cursor-pointer py-1 px-2 border w-1/2 text-sbase font-bold  text-white'>Current Donor reservation  </div>
                                <div className='cursor-pointer py-1 px-2 border w-1/2 text-sbase font-bold  text-white'>Code</div>
                            </div>
                            <div className={`flex mb-0.5  ${theme.hoverBg}`}>
                                <div className='cursor-pointer py-1 px-2 border w-2/4 text-sbase font-bold text-gray-700'>Sperm</div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='spermCode' defaultValue={formData?.spermCode} type='checkbox' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                </div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='spermCodeTxt' defaultValue={formData?.spermCodeTxt} type='text' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  w-full rounded text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                </div>
                            </div>
                            <div className={`flex mb-0.5  ${theme.hoverBg}`}>
                                <div className='cursor-pointer py-1 px-2 border w-2/4 text-sbase font-bold text-gray-700'>Egg</div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='eggCode' defaultValue={formData?.eggCode} type='checkbox' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} /></div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='spermCodeTxt' defaultValue={formData?.spermCodeTxt} type='text' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  w-full rounded text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                </div>
                            </div>
                            <div className={`flex mb-0.5  ${theme.hoverBg}`}>
                                <div className='cursor-pointer py-1 px-2 border w-2/4 text-sbase font-bold text-gray-700'>Embryo</div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='embryoCode' defaultValue={formData?.embryoCode} type='checkbox' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} /></div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='embryoCodeTxt' defaultValue={formData?.embryoCodeTxt} type='text' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + ' w-full rounded text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                </div>
                            </div>
                        </div>
                    }
                    {formData.doner === 3 &&
                        <div className='w-full flex justify-between gap-x-2 px-1'>
                            <div className='w-1/2  shadow'>
                                <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                                    <p className='text-sbase font-medium'>PATIENT BMI calculation</p>
                                </div>
                                <div className='w-full flex flex-wrap items-center justify-between py-2 px-3'>
                                    <div className={'flex items-center justify-between w-full '}>
                                        <label className={" text-sm "}>Height (cm)</label>
                                        <div className='relative cursor-pointer  '>
                                            <input type='number' onKeyDown={preventInvalidNumberInput}
                                                // onChange={(e) => {
                                                //     handleInputChange('patientHeight', numberLimter(e.target.value, 999));
                                                // }}
                                                name='patientHeight'
                                                onChange={(e) => handleInputChange(e)}
                                                value={formData.patientHeight}

                                                className={theme.cheqColor + ' shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '}
                                            />
                                        </div>
                                    </div>
                                    <div className={'flex items-center justify-between w-full '}>
                                        <label className={" text-sm "}>Weight (kg)</label>
                                        <div className='relative cursor-pointer  '>
                                            <input
                                                type='number'
                                                onKeyDown={preventInvalidNumberInput}
                                                // onChange={(e) => {
                                                //     handleInputChange('patientWeight', numberLimter(e.target.value, 999));
                                                // }}
                                                name='patientWeight'
                                                onChange={(e) => handleInputChange(e)}
                                                value={formData.patientWeight}

                                                className={theme.cheqColor + ' shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '}
                                            />
                                        </div>
                                    </div>
                                    <div className={'flex items-center justify-between w-full pt-0.5'}>
                                        <label className={"text-sm "}>BMI</label>
                                        <div className='relative cursor-pointer flex items-center'>
                                            <input
                                                type='text'
                                                name='patientBMI'
                                                value={formData.patientBMI || ''}
                                                disabled={true}
                                                className={theme.cheqColor + ' shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '}
                                            />
                                            {/* <p>{formData?.patientBMI > 0 && getBMITick(formData.patientBMI || '')?.icon}</p> */}

                                        </div>
                                    </div>
                                    {formData.patientBMI >= 35 && <label className={" text-sm text-red-600 font-semibold  "} >Partner is OverWeight  </label>}
                                    {formData.patientWeight >= 100 && <label className={" text-sm text-red-600 font-semibold  "} >Partner Weight is More then 100 kg </label>}

                                </div>
                            </div>

                            <div className='w-1/2  shadow '>
                                <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t '}>
                                    <p className='text-sbase font-medium'>PARTNER BMI calculation</p>
                                </div>
                                <div className='w-full flex flex-wrap items-center justify-between py-2 px-3'>
                                    <div className={'flex items-center justify-between w-full '}>
                                        <label className={" text-sm "} >Height (cm)</label>
                                        <div className='relative cursor-pointer  '>
                                            <input type='number' onKeyDown={preventInvalidNumberInput} name='partnerHeight'
                                                onChange={(e) => handleInputChange(e)}
                                                // onChange={(e) => {
                                                //     handleInputChange('partnerHeight', numberLimter(e.target.value, 999));
                                                // }}
                                                value={formData.partnerHeight}

                                                className={theme.cheqColor + ' shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                        </div>
                                    </div>
                                    <div className={'flex items-center justify-between w-full '}>
                                        <label className={" text-sm  "} >Weight (kg)</label>
                                        <div className='relative cursor-pointer  '>
                                            <input type='number' onKeyDown={preventInvalidNumberInput} name='partnerWeight'
                                                // onChange={(e) => {
                                                //     handleInputChange('partnerWeight', numberLimter(e.target.value, 999));
                                                // }}
                                                onChange={(e) => handleInputChange(e)}
                                                value={formData.partnerWeight} className={theme.cheqColor + ' shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                        </div>
                                    </div>
                                    <div className={'flex items-center justify-between w-full pt-0.5'}>
                                        <label className={" text-sm  "} >BMI</label>
                                        <div className='relative cursor-pointer flex items-center '>
                                            <input type='text' name='partnerBMI' disabled={true} value={formData.partnerBMI || ''} className={theme.cheqColor + ' shadow border rounded rounded-xl px-2  text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />

                                            {/* <p>{formData?.partnerBMI > 0 && getBMITick(formData.partnerBMI || '')?.icon}</p> */}

                                        </div>
                                    </div>
                                    {formData.partnerBMI >= 35 && <label className={" text-sm text-red-600 font-semibold  "} >Partner is OverWeight  </label>}
                                    {formData.partnerWeight >= 100 && <label className={" text-sm text-red-600 font-semibold  "} >Partner Weight is More then 100 kg </label>}
                                </div>
                            </div>
                        </div>

                    }
                </div>

                <div className=' w-5/12 flex '>
                    {formData.doner !== 3 &&
                        <div className={'justify-between text-left px-2  pt-0  w-full border-r-[2px]  border-r-gray-200    '}>
                            <div className={theme.tHedUpdte + ' flex text-white text-left p-1 rounded-t'}>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase font-bold  text-white'>Consents  </div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase font-bold  text-white'>Requires</div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase font-bold  text-white'>Email/Portal  </div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase font-bold  text-white'>Completed</div>
                            </div>
                            <div className={`flex mb-0.5  ${theme.hoverBg}`}>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase font-bold text-gray-700'>C001-P</div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='C001PRequired' defaultValue={formData?.C001PRequired} type='checkbox' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                </div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='C001PDate' defaultValue={formData?.C001PDate} type='date' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                </div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='C001PCompleted' defaultValue={formData?.C001PCompleted} type='checkbox' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} /></div>
                            </div>
                            <div className={`flex mb-0.5  ${theme.hoverBg}`}>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase font-bold text-gray-700'>IUI C2</div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='IUIC2Required' defaultValue={formData?.IUIC2Required} type='checkbox' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} /></div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='IUIC2Date' defaultValue={formData?.IUIC2Date} type='date' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                </div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='IUIC2Completed' defaultValue={formData?.IUIC2Completed} type='checkbox' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} /></div>
                            </div>
                            <div className={`flex mb-0.5  ${theme.hoverBg}`}>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase font-bold text-gray-700'>N201 IUI only</div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='N201IUIonlyRequired' defaultValue={formData?.N201IUIonlyRequired} type='checkbox' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} /></div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='N201IUIonlyDate' defaultValue={formData?.N201IUIonlyDate} type='date' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                </div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='N201IUIonlyCompleted' defaultValue={formData?.N201IUIonlyCompleted} type='checkbox' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} /></div>
                            </div>
                            <div className={`flex mb-0.5  ${theme.hoverBg}`}>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase font-bold text-gray-700'>PGT C10</div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='pgtC10Required' defaultValue={formData?.pgtC10Required} type='checkbox' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} /></div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='pgtC10Date' defaultValue={formData?.pgtC10PDate} type='date' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />  </div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='pgtC10Completed' defaultValue={formData?.pgtC10Completed} type='checkbox' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} /></div>
                            </div>
                            {formData.doner === 2 && <div className={`flex mb-0.5  ${theme.hoverBg}`}>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase font-bold text-gray-700'>SurrogacyC55</div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='surrogacyC55Required' defaultValue={formData?.surrogacyC55Required} type='checkbox' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} /></div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='surrogacyC55Date' defaultValue={formData?.surrogacyC55Date} type='date' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                </div>
                                <div className='cursor-pointer py-1 px-2 border w-1/4 text-sbase  text-gray-700'>
                                    <input name='surrogacyC55Completed' defaultValue={formData?.surrogacyC55Completed} type='checkbox' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                </div>
                            </div>}


                        </div>}
                    {formData.doner === 3 && <div className={'justify-between text-left px-2  pt-0  w-full border-r-[2px]  border-r-gray-200    '}>
                        <div className={theme.tHedUpdte + ' flex text-white text-left p-1 rounded-t'}>
                            <div className='cursor-pointer py-1 px-2 border w-1/2 text-sbase font-bold  text-white'>Consents  </div>

                            <div className='cursor-pointer py-1 px-2 border w-1/2 text-sbase font-bold  text-white'>Completed</div>
                        </div>
                        <div className={`flex mb-0.5  ${theme.hoverBg}`}>
                            <div className='cursor-pointer py-1 px-2 border w-1/2 text-sbase font-bold text-gray-700'>Donor C60  </div>
                            <div className='cursor-pointer py-1 px-2 border w-1/2 text-sbase  text-gray-700'>
                                <input name='donorC60Completed' defaultValue={formData?.donorC60Completed} type='checkbox' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                            </div>
                        </div>
                        <div className={`flex mb-0.5  ${theme.hoverBg}`}>
                            <div className='cursor-pointer py-1 px-2 border w-1/2 text-sbase font-bold text-gray-700'>Valid till</div>
                            <div className='cursor-pointer py-1 px-2 border w-1/2 text-sbase  text-gray-700'>
                                <input name='validTill' defaultValue={formData?.validTill} type='date' onChange={(e) => { handleInputChange(e) }} className={theme.cheqColor + '  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                            </div>
                        </div>
                    </div>}


                    {/* <div className={theme.panelBg + 'mt-2 rounded flex justify-end items-center  pt-4'}>
                        <button className="bg-transparent mx-1 hover:bg-red-500 text-red-500 font-semibold text-sm hover:text-white  px-2 border border-red-300 hover:border-transparent rounded" > Cancel  </button>
                        <button className="bg-transparent ml-1 hover:bg-green-600 text-green-800 font-semibold text-sm hover:text-white  px-2 border border-green-500 hover:border-transparent rounded" onClick={() => { setShowModal(true); checkResults() }} >  Save</button>

                    </div> */}
                </div>
            </div>

            {/* -------------------- */}
            {/* {ShowModal ? (

                <PinModal title="Saving Blood Result" successFunction={handleSubmit} />

            ) : ''} */}

            <div className='flex justify-end p-2 border shadow'>
                <button className="bg-transparent ml-5 hover:bg-green-600 text-green-800 font-semibold text-sm hover:text-white py-1 px-2 border border-green-500 hover:border-transparent rounded"
                    onClick={() => { saveNursingCheckList() }}>Save </button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_POST, ApiCall_GET }

export default connect(mapStateToProps, mapDispatchToProps)(NursingCheckList)
