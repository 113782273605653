import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../AppContextProvider ';
import PinModal from '../../../Components/PinModal';
import { ApiCall_GET, ApiCall_POST } from '../../../../Redux/Generic/action';
import { getSession } from '../../../../session';
import { showNotificationMessage } from '../../../../common';

export const ChangeGender = ({ ApiCall_POST, ApiCall_GET, coupleData, setGenderPopUp, handlecollection, handlecancel, handlesave, }) => {

    const theme = useAppContext().themeDetails;
    const [Patientdata, setPatientdata] = useState("");
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        ApiCall_GET('Patient/GetPersonDetails/' + coupleData?.CoupleId, true, false).then(function (Payload) {
            setPatientdata(Payload)
        });

    }, [])


    const [postDataList, setPostDataList] = useState([
        {
            personid: coupleData?.PatientId,
            personsex: coupleData?.PatientGender,
        },
        {
            personid: coupleData?.PartnerId,
            personsex: coupleData?.PartnerGender,
        },
    ])
    const [formData, setFormData] = useState(
        {
            PatientId: coupleData?.PatientId,
            PartnerId: coupleData?.PartnerId,
            patientSex: coupleData?.PatientGender,
            PatientFullName: coupleData?.PatientFullName,
            partnerSex: coupleData?.PartnerGender,
            PartnerFullName: coupleData?.PartnerFullName,

        }


    );


    const handleInputChange = (e, pesronId) => {
        const { name, value, checked, type } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }))

        setPostDataList(prevDataList => {
            const index = prevDataList.findIndex(item => item.personid === pesronId);
            var obj = {
                personid: pesronId,
                personsex: value
            }

            if (index !== -1) {
                const updatedList = [...prevDataList];
                updatedList[index] = obj;
                return updatedList;
            } else {
                return [...prevDataList, obj];
            }
        })


        if (name === "patientSex" && ((Patientdata?.PatientAndrologyCount !== 0) || (Patientdata?.PatientSpermCount !== 0) || (Patientdata?.PatientEmbryoCount !== 0))) {
            showNotificationMessage('error', 'Patient has Frozen Ooc/Embryos,Andrology Or Frozen SpermF in storage. Change of gender may render the records inaccessible.', true)
        }
        if (name === "partnerSex" && ((Patientdata.PartnerAndrologyCount !== 0 || Patientdata.PartnerSpermCount !== 0 || Patientdata.PartnerEmbryoCount !== 0))) {
            showNotificationMessage('error', 'Partner has Frozen Ooc/Embryos,Andrology Or Frozen SpermF in storage. Change of gender may render the records inaccessible.', true)
        }
    }

    const handleSubmit = () => {
        ApiCall_POST(`patient/ChangePersonSex`, postDataList, true).then(function (payload) {
            showNotificationMessage('success', 'Gender Changes successfully!', true)
            setGenderPopUp(false)
            handlecollection()
        });

    };


    return (

        <div className="fixed z-50  inset-0 overflow-y-auto ">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div onClick={() => { setGenderPopUp(false) }}
                    style={{ backdropFilter: "blur(7px)" }} className="fixed inset-0 transition-opacity filter" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-700 opacity-70  "></div></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl "
                    aria-hidden="true"></span>
                {/* <Draggable> */}
                <div
                    className={`sm:my-8   w-2/3 filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`}
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative  `}>
                        <svg className={" absolute top-2 right-2 cursor-pointer shadow  font-semibold  "}
                            onClick={() => { setGenderPopUp(false) }}
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                className="hover:shadow   "
                                d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z"
                                fill="#ed4545"
                            />
                        </svg>
                        <h3 className={theme.txt_color + " text-xl text-center mb-6 mt-2 leading-6 font-bold text-gray-900"} id="modal-headline">Change Sex/Gender of Patient and/or Partner</h3>

                        <div className=" my-2 w-full border rounded-md shadow items-center bg-gray-100 p-4  rounded-lg"
                            onMouseDown={(e) => e.stopPropagation()}                        >
                            <div className="mb-2">
                                <div id="alert-4" className="flex items-center self-end shadow mb-1 p-2  justify-center border rounded-lg bg-yellow-300 " role="alert">
                                    <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#664d03" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                    </svg>
                                    <span className="sr-only">Info</span>
                                    <div className="ms-3 text-sm font-medium">
                                        <i> ~ IMPORTANT: Physical gender settings affect many of the database rules that are applied to the patient/couple throughout the system. Take great care if modifying these settings.. ~</i>
                                    </div>
                                </div>
                                <div className={` w-full shadow items-center pb-1 my-2 font-normal   `}>
                                    <div className={`flex justify-between  text-left px-2 rounded-t`}>
                                        {/* <p className='text-sm font-medium'>Patient - {formData.PatientFullName}</p> */}
                                        <label className={`${theme.txt_color} font-semibold cursor-pointer  text-white pt-1 border-b-[2px] border-b-amber-500`}>Patient - {formData.PatientFullName}</label>


                                        {formData.patientSex === '2' ?
                                            <svg width="15" height="20" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="white" />
                                            </svg>
                                            :
                                            <svg width="17" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="White" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        }
                                    </div>
                                    <div className='w-full items-center  flex font-normal '>
                                        <div className='w-1/2 text-left   px-2   justify-between'>
                                            <p className={" text-sm font-medium  "} >Gender</p>
                                            <div className={`flex flex-wrap gap-x-8  p-2`} >
                                                <p className={" text-sm font-medium"} >Born As</p>
                                                <div className='flex gap-x-4 '>
                                                    <label className="flex items-center gap-x-0.5">
                                                        <input
                                                            className='mr-1'
                                                            type="radio"
                                                            name="patientSex"
                                                            value="2"
                                                            checked={formData.patientSex == 2}
                                                            onChange={(e) => handleInputChange(e, coupleData?.PatientId)}
                                                        />
                                                        {/* <input type="radio" className={theme.cheqColor + ' border-0 '} name='bornAs' value="Female" checked={formData.gender === 'Female'} onClick={(e) => handleInputChange(e)} /> */}
                                                        <svg width="17" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="#FE9696" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        <span className=" text-sm  text-gray-800 dark:text-gray-600">Female</span>
                                                    </label>
                                                    <label className="flex items-center gap-x-0.5">
                                                        <input
                                                            className='mr-1'
                                                            type="radio"
                                                            name="patientSex"
                                                            value="1"
                                                            checked={formData.patientSex == 1}
                                                            onChange={(e) => handleInputChange(e, coupleData?.PatientId)}
                                                        />
                                                        <svg width="15" height="18" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="#A9C6FF" />
                                                        </svg>
                                                        {/* <input type="radio" className={theme.cheqColor + ' border-0 '} name='bornAs' value="Male" checked={formData.gender === 'Male'} onClick={(e) => handleInputChange(e)} /> */}
                                                        <span className=" cursor-pointer text-sm    text-gray-700 ">Male</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`w-1/2 text-left flex items-center px-2 `}>
                                            <div className={`w-full  bg-white rounded p-2 shadow hover:bg-gray-100 ${theme.hoverBg}`}>
                                                <div className='flex items-center'>
                                                    <div className='w-full'>
                                                        <p className={`text-sm ${Patientdata?.PatientAndrologyCount > 0 ? 'text-red-600' : 'text-gray-700'} font-medium`}><i>{Patientdata?.PatientAndrologyCount} x</i> Linked Andrology Cycle :</p>
                                                    </div>
                                                </div>
                                                <div className='flex items-center'>
                                                    <div className='w-full'>
                                                        <p className={`text-sm ${Patientdata?.PatientSpermCount > 0 ? 'text-red-600' : 'text-gray-700'} font-medium`}><i>{Patientdata?.PatientSpermCount} x</i> Frozen Sperm in Stock :</p>
                                                    </div>
                                                </div>
                                                <div className='flex items-center'>
                                                    <div className='w-full'>
                                                        <p className={`text-sm ${Patientdata?.PatientEmbryoCount > 0 ? 'text-red-600' : 'text-gray-700'} font-medium`}><i>{Patientdata?.PatientEmbryoCount} x</i> Frozen Ooc/Embryos in Stock  :</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(Patientdata?.PatientAndrologyCount !== 0) || (Patientdata?.PatientSpermCount !== 0) || (Patientdata?.PatientEmbryoCount !== 0) ?
                                        <p className='w-[98%] m-auto self-center text-sm font-medium text-gray-600 bg-red-200 text-center px-2 mt-1 rounded-md py-1'><i>WARNING: any NON-ZERO counter above, indicates that certain records might be lost by changing the physical gender setting of this person record.</i></p>
                                        :
                                        <p className='w-[98%] m-auto self-center text-sm font-medium text-gray-600 bg-green-200 text-center px-2 mt-1 rounded-md py-1'><i> All counts are zero, therefore it is probably safe to change the physical gender setting of this person record.</i></p>
                                    }

                                </div>
                                <div className={` w-full shadow items-center pb-1 mb-2 font-normal   `}>
                                    <div className={` flex justify-between  text-left rounded-t`}>
                                        <label className={`${theme.txt_color} font-semibold ml-2 cursor-pointer  text-white pt-1 border-b-[2px] border-b-amber-500`}>Partner - {formData.PartnerFullName}</label>
                                        {/* <p className='text-sm font-medium'>Partner - {formData.PartnerFullName}</p> */}
                                        {formData.partnerSex === '2' ?
                                            <svg width="15" height="20" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="white" />
                                            </svg>
                                            :
                                            <svg width="17" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="White" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        }
                                    </div>
                                    <div className='w-full items-center  flex font-normal '>
                                        <div className='w-1/2 text-left   px-2  pt-2  justify-between'>
                                            <p className={" text-sm font-medium  "} >Gender</p>
                                            <div className={`flex flex-wrap gap-x-8  p-2`} >
                                                <p className={" text-sm font-medium"} >Born As</p>
                                                <div className='flex gap-x-4 '>
                                                    <label className="flex items-center gap-x-0.5">
                                                        <input
                                                            className='mr-1'
                                                            type="radio"
                                                            name="partnerSex"
                                                            value="2"
                                                            checked={formData.partnerSex == 2}
                                                            onChange={(e) => handleInputChange(e, coupleData?.PartnerId)}
                                                        />
                                                        {/* <input type="radio" className={theme.cheqColor + ' border-0 '} name='bornAs' value="Female" checked={formData.gender === 'Female'} onClick={(e) => handleInputChange(e)} /> */}
                                                        <svg width="17" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="#FE9696" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        <span className=" text-sm  text-gray-800 dark:text-gray-600">Female</span>
                                                    </label>
                                                    <label className="flex items-center gap-x-0.5">
                                                        <input
                                                            className='mr-1'
                                                            type="radio"
                                                            name="partnerSex"
                                                            value="1"
                                                            checked={formData.partnerSex == 1}
                                                            onChange={(e) => handleInputChange(e, coupleData?.PartnerId)}
                                                        />
                                                        <svg width="15" height="18" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="#A9C6FF" />
                                                        </svg>
                                                        {/* <input type="radio" className={theme.cheqColor + ' border-0 '} name='bornAs' value="Male" checked={formData.gender === 'Male'} onClick={(e) => handleInputChange(e)} /> */}
                                                        <span className=" cursor-pointer text-sm    text-gray-700 ">Male</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`w-1/2 text-left flex items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                            <div className='w-full my-0.5 bg-white rounded p-2 shadow hover:bg-gray-100'>
                                                <div className='flex items-center'>
                                                    <div className='w-full'>
                                                        <p className={`text-sm ${Patientdata?.PartnerAndrologyCount > 0 ? 'text-red-600 ' : 'text-gray-700'} font-medium`}><i>{Patientdata?.PartnerAndrologyCount} x</i> Linked Andrology Cycle 3:</p>
                                                    </div>
                                                </div>
                                                <div className='flex items-center'>
                                                    <div className='w-full'>
                                                        <p className={`text-sm ${Patientdata?.PartnerSpermCount > 0 ? ' text-red-600 ' : 'text-gray-700'} font-medium`}><i>{Patientdata?.PartnerSpermCount} x</i> Frozen Sperm in Stock :</p>
                                                    </div>
                                                </div>
                                                <div className='flex items-center'>
                                                    <div className='w-full'>
                                                        <p className={`text-sm ${Patientdata?.PartnerEmbryoCount > 0 ? ' text-red-600 ' : 'text-gray-700'} font-medium`}><i>{Patientdata?.PartnerEmbryoCount} x</i> Frozen Ooc/Embryos in Stock  :</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {(Patientdata?.PartnerAndrologyCount !== 0) || (Patientdata?.PartnerSpermCount !== 0) || (Patientdata?.PatientEmbryoCount !== 0) ?
                                        <p className='w-[98%] m-auto self-center text-sm font-medium text-gray-600 bg-red-200 text-center px-2 mt-1 rounded-md py-1'><i>WARNING: any NON-ZERO counter above, indicates that certain records might be lost by changing the physical gender setting of this person record.</i></p>
                                        :
                                        < p className='w-[98%] m-auto self-center text-sm font-medium text-gray-600 bg-green-200 text-center px-2 mt-1 rounded-md py-1'><i> All counts are zero, therefore it is probably safe to change the physical gender setting of this person record.</i></p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className=' bg-white  py-2 z-100 rounded flex justify-end items-center border px-6'>
                            <button className="bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded  mr-3"
                                onClick={() => { setGenderPopUp(false) }}>Cancel</button>
                            <button className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded"
                                onClick={() => { setShowModal(true) }}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <PinModal
                    title="Change Sex/Gender  of Couple"
                    setShowModal={setShowModal}
                    successFunction={(e) => handleSubmit(e)}
                />
            )}
        </div >
    )
}

ChangeGender.propTypes = {

}

const mapStateToProps = (state) => ({})




const mapDispatchToProps = { ApiCall_POST, ApiCall_GET };

export default connect(mapStateToProps, mapDispatchToProps)(ChangeGender)
