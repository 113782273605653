import React, { useState } from 'react'
import { connect } from 'react-redux'
import { getCorrectDate, getCorrectDateForInput } from '../../../../../../../common'
import { ApiCall_GET, ApiCall_POST } from '../../../../../../../Redux/Generic/action'
import Swal from 'sweetalert2'

export const AddDayOne = ({ rowData, ApiCall_POST, isDone, artId }) => {
    const [dayOne, setDayOne] = useState(getCorrectDateForInput(rowData.DayOneofCycleDate))

    const handleSave = () => {

        if (dayOne) {
            var formObj = {
                artid: artId,
                dayoneofcycle: dayOne,
                runningsheetid: rowData.Id
            }

            ApiCall_POST('Art/SaveDayOneOfCycleInArt', formObj, true).then(function (payload) {
                Swal.fire({
                    icon: 'success',
                    title: 'Day one have been saved.',
                    showConfirmButton: false,
                    timer: 1500
                })
                window.location.reload();
            })
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Day one not selected.',
                showConfirmButton: true
            })
        }
    }

    return (
        <div className={' bg-yellow-50   p-5'}>
            <p className='text-sm font-medium ml-5'>{'>'} {isDone ? '' : 'Add'} Day 1 Date</p>
            <div className='w-full flex items-center pt-3'>
                <p className='text-sm font-medium ml-10'> {isDone ? 'Selected' : 'Select'} Date</p>
                <input type='date' onChange={(e) => { setDayOne(e.target.value) }} className='ml-3  px-2 py-1 text-sm  shadow  rounded-full' disabled={isDone} defaultValue={getCorrectDateForInput(rowData.DayOneofCycleDate)} />
                {isDone ? '' :
                    <button onClick={handleSave} className="bg-transparent ml-3 hover:bg-green-600 text-green-800 font-semibold text-sm hover:text-white px-2 py-1 border border-green-500 hover:border-transparent rounded">  Save</button>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_POST }

export default connect(mapStateToProps, mapDispatchToProps)(AddDayOne)