import React, { useState, useEffect } from "react";
import doctor from "../../Assets/images/doctor.png";
import "../../App.css";
import { resetSession } from "../../session";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { ApiCall_POST, ApiCall_GET } from "../../Redux/Generic/action";
import { useAppContext } from "../../AppContextProvider ";
import Swal from "sweetalert2";
import ServiceUrls from "../../Common/ServiceUrls";

export const SearchBar = ({ ApiCall_POST, ApiCall_GET, ...otherProps }) => {
  const navigate = useNavigate();
  const { themeDetails, setThemeDetails } = useAppContext({});
  const [totalActionCount, setTotalActionCount] = useState("");

  useEffect(() => {
    getDepartmentWiseCounts();
  }, []);

  const handleLogout = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton:
          "bg-transparent flex justify-between items-center mx-1  hover:bg-green-600 text-green-700 font-medium hover:text-white py-1 px-5 border-2 border-green-500 hover:border-transparent rounded",
        cancelButton:
          "bg-transparent flex justify-between items-center  mx-1 hover:bg-red-600 text-red-700 font-medium hover:text-white py-1 px-5 border-2 border-red-500 hover:border-transparent rounded",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Logout Confirmation",
        text: "Are you sure you want to logout?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        // timerProgressBar: true, timer: 6000,
      })
      .then((result) => {
        if (result.isConfirmed) {
          setThemeDetails({});
          ApiCall_POST("UserManagment/Logout", null, true).then(function (payload) {
            if (payload === 1) {
              resetSession("patient");
              resetSession("user");
              localStorage.setItem("selectedPage", undefined);
              localStorage.setItem("coupleId", undefined);
              navigate("/login");
            } else {
              alert("Something went wrong.");
            }
          });
        }
      });
  };

  const getDepartmentWiseCounts = () => {
    ApiCall_GET(ServiceUrls.GetTotalActionCount, true).then(function (payload) {
      setTotalActionCount(payload);
    });
  };

  return (
    <div className="displayFlex h-full justify-end">
      <div
        onClick={() =>
          navigate("/Action", { state: { user: otherProps.user } })
        }
        className="flex items-center  pr-2 cursor-pointer hover:scale-125 duration-200"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="fill-gray-300 hover:fill-yellow-400 relative"
        >
          <path d="M19.2223 17.3815C19.1479 17.2135 19.1311 16.9687 19.1935 16.7959C19.6759 15.4279 20.1703 14.0671 20.6839 12.7087C21.2311 11.2663 21.6559 9.81429 21.4423 8.23749C21.1999 6.44709 20.4655 4.89669 19.1431 3.67269C18.4975 3.07749 17.7415 2.60949 16.9927 2.05269C17.0503 1.89429 17.1439 1.64709 17.2351 1.39989C17.3839 0.991893 17.2687 0.672693 16.9255 0.545493C16.5727 0.415893 16.2775 0.562293 16.1119 0.958293C16.0039 1.21269 15.9079 1.46949 15.8023 1.73349C14.8039 1.48869 13.8175 1.39029 12.8167 1.53429C9.8335 1.96149 7.6807 3.52629 6.5167 6.31749C5.8039 8.03349 5.2159 9.80229 4.5823 11.5495C4.5055 11.7655 4.3831 11.8927 4.1743 11.9863C3.1135 12.4615 2.0599 12.9511 0.999097 13.4335C0.715897 13.5607 0.504697 13.7407 0.511897 14.0767C0.519097 14.4247 0.763897 14.5639 1.0519 14.6671C3.0319 15.3775 5.0071 16.0951 6.9919 16.7911C7.2895 16.8967 7.3927 17.0143 7.3759 17.3431C7.2751 19.5463 9.3415 21.1879 11.4799 20.6287C12.3463 20.4031 13.0063 19.8967 13.5031 19.1431C13.6063 19.1791 13.6999 19.2127 13.7935 19.2439C15.8047 19.9639 17.8159 20.6839 19.8271 21.4087C20.1295 21.5167 20.4079 21.5623 20.6575 21.3055C20.9167 21.0367 20.8087 20.7607 20.6743 20.4775C20.1799 19.4527 19.6879 18.4231 19.2223 17.3815ZM10.1191 19.4599C9.1615 19.1839 8.5279 18.3247 8.5951 17.3455C9.8479 17.7919 11.0743 18.2311 12.3055 18.6703C11.9551 19.3423 10.9495 19.6999 10.1191 19.4599Z" />
        </svg>
        <span className="relative flex items-center justify-center h-4 mr-4  px-1 w- rounded-full">
          <span className="absolute top-0 inline-flex rounded-full h-3 px-0.5 bg-red-500"><label className={` text-white text-xss`}> {totalActionCount.AllDepartmentCount}</label></span>
        </span>
      </div>
      <div className="flex items-center  pr-2 cursor-pointer hover:scale-125 duration-200 ">
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="fill-gray-300 hover:fill-yellow-400"
          onClick={handleLogout}
        >
          <path d="M7.75 14.75C7.75 14.5511 7.82902 14.3603 7.96967 14.2197C8.11032 14.079 8.30109 14 8.5 14H14.5C14.5663 14 14.6299 13.9737 14.6768 13.9268C14.7237 13.8799 14.75 13.8163 14.75 13.75V1.75C14.75 1.6837 14.7237 1.62011 14.6768 1.57322C14.6299 1.52634 14.5663 1.5 14.5 1.5H8.5C8.30109 1.5 8.11032 1.42098 7.96967 1.28033C7.82902 1.13968 7.75 0.948912 7.75 0.75C7.75 0.551088 7.82902 0.360322 7.96967 0.21967C8.11032 0.0790175 8.30109 0 8.5 0H14.5C15.466 0 16.25 0.784 16.25 1.75V13.75C16.25 14.2141 16.0656 14.6592 15.7374 14.9874C15.4092 15.3156 14.9641 15.5 14.5 15.5H8.5C8.30109 15.5 8.11032 15.421 7.96967 15.2803C7.82902 15.1397 7.75 14.9489 7.75 14.75Z" />
          <path d="M12.1126 8.86502C12.1126 9.13023 12.0072 9.38459 11.8197 9.57212C11.6321 9.75966 11.3778 9.86502 11.1126 9.86502H6.25658C6.23358 10.221 6.20458 10.575 6.17058 10.931L6.14058 11.236C6.12941 11.351 6.09067 11.4615 6.02766 11.5583C5.96465 11.6551 5.87922 11.7352 5.77862 11.792C5.67802 11.8487 5.56522 11.8803 5.44979 11.8842C5.33437 11.888 5.21972 11.8639 5.11558 11.814C3.28756 10.9379 1.63269 9.73902 0.230577 8.27501L0.200577 8.24402C0.0718637 8.10978 0 7.93099 0 7.74502C0 7.55904 0.0718637 7.38026 0.200577 7.24602L0.230577 7.21502C1.63268 5.751 3.28754 4.55211 5.11558 3.67602C5.21972 3.62609 5.33437 3.60203 5.44979 3.60587C5.56522 3.6097 5.67802 3.64133 5.77862 3.69805C5.87922 3.75478 5.96465 3.83494 6.02766 3.93172C6.09067 4.02851 6.12941 4.13907 6.14058 4.25402L6.17058 4.55902C6.20458 4.91402 6.23358 5.26902 6.25658 5.62502H11.1126C11.3778 5.62502 11.6321 5.73037 11.8197 5.91791C12.0072 6.10545 12.1126 6.3598 12.1126 6.62502V8.86502Z" />
        </svg>
      </div>
      <img src={doctor} width="30px" className="leftRightMargin" alt="doc" />
      <p className=" text-white text-sm pr-2">
        {otherProps.user
          ? otherProps.user.UserName
            ? otherProps.user.UserName
            : "No Name"
          : "No User"}
      </p>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { ApiCall_POST, ApiCall_GET };

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
