import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { calculateDifference, getCorrectDate, getFormattedDateForInput, getUserInformation } from '../../../../../../../common'
import { useState } from 'react';
import { ApiCall_GET, ApiCall_POST } from '../../../../../../../Redux/Generic/action';
import Swal from 'sweetalert2';
import Draggable from 'react-draggable';
import PinModal from '../../../../../../Components/PinModal';
import { useAppContext } from '../../../../../../../AppContextProvider ';

export const UpdateInCycleBlood = ({ scanList, ApiCall_POST, artId }) => {
    const [formObj, setFormObj] = useState(null);
    const [nextScanDay, setNextScanDay] = useState(0);
    const [ShowModal, setShowModal] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [nextBloodDay, setNextBloodDay] = useState(0);
    const theme = useAppContext().themeDetails;

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'testdate') {
            setFormObj({
                ...formObj,
                testdayofcycle: calculateDifference(formObj?.dayoneofcycle, getCorrectDate(value)) + 1, //getCycleDay(formObj?.dayoneofcycle, getCorrectDate(value)),
                [name]: value,
            });
        }
        else {
            setFormObj({
                ...formObj,
                [name]: value,
            });
        }

    };
    useEffect(() => {
        var object = {
            artId: artId,
            dayoneofcycle: scanList[0].dayoneofcycle,
            coupleid: scanList[0].coupleid,
            logdayonedate: scanList[0].dayoneofcycle,
            testdate: (new Date()).toISOString().substr(0, 10),
            testdayofcycle: calculateDifference(scanList[0].dayoneofcycle, (new Date()).toISOString().substr(0, 10)) + 1,
            comments: "",
            e2_dose: "",
            fsh_dose: "",
            hcg_dose: "",
            lh_dose: "",
            p4_dose: ""
        };
        object.testdayofcycle = calculateDifference(object.dayoneofcycle, object.testdate) + 1;
        setFormObj(object)

        // ApiCall_GET('UserManagment/GetClinicianList', true).then(function (payload) {
        //     setClinicianlist(payload);
        // });

    }, [])

    const handleSubmit = () => {
        setShowModal(false)
        ApiCall_POST('Art/SaveBloodTestDetails', formObj, true).then(function (payload) {
            Swal.fire({
                icon: 'success',
                title: 'Blood test details has been saved.',
                showConfirmButton: false,
                timer: 1500
            })
            window.location.reload();
            setShowModal(false)
        });
    }

    const checkResults = () => {
        if (formObj.e2_dose || formObj.fsh_dose || formObj.hcg_dose || formObj.lh_dose || formObj.p4_dose) {
            setShowModal(true);
        }
        else {
            setShowModal(false)

            Swal.fire({
                icon: 'error',
                title: 'Please enter the blood results',
                showConfirmButton: true
            })
            return;
        }
    }

    const handlePinChange = (e) => {
        var value = e.target.value
        if (value.length > 4) {
            var userInfo = getUserInformation()
            if (userInfo.PinNumber === Number(value)) {
                handleSubmit();
                setShowModal(false);
            } else {
                setShowAlert(true);
            }
        } else {
            setShowAlert(false);
        }
        return;
    };

    return (
        <div onDoubleClick={(e) => e.stopPropagation()} className={" rounded  p-2 my-1 w-[99%] m-auto  shadow border"}>
            <div className='flex justify-between'>
                <label className={theme.txt_color + "  text-sm  font-bold "} >Day : {formObj?.testdayofcycle && formObj?.testdate !== '' ? formObj?.testdayofcycle : <span className='text-sm text-red-400 font-medium '>--</span>}</label>
            </div>
            <div className={theme.panelBg + ' flex   rounded'}>
                <div className='  w-3/12'>
                    <div className={'pt-2 flex items-center'}>
                        <p className={" text-sm mx-2  w-36"} >Day One of Cycle </p>
                        <input type="text" value={formObj?.dayoneofcycle} disabled={true} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                    </div>
                    <div className={'pt-2 flex items-center'}>
                        <label htmlFor="testdate" className={" text-sm mx-2 w-36  "} >Test Date</label>
                        <input type='date' name='testdate' defaultValue={getFormattedDateForInput(formObj?.testdate)} min={formObj && formObj?.testdate && getCorrectDate(formObj?.testdate).split('-').reverse().join('-')} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />

                    </div>
                    <div className={'pt-2 flex items-center'}>
                        <label className={" text-sm mx-2 w-36 "} >Next Blood Test Date</label>
                        <input name='NextBloodTestDate' defaultValue={formObj?.NextBloodTestDate} type='date' onChange={(e) => { handleInputChange(e); setNextBloodDay(calculateDifference(formObj?.dayoneofcycle ?? formObj?.dayoneofcycle, e.target.value) + 1) }} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                        <i className='text-gray-400 text-sm ml-3'>{formObj?.NextBloodTestDate && nextBloodDay ? '~ Day-' + nextBloodDay : ''}</i>
                    </div>
                    <div className={'pt-2 flex items-center'}>
                        <label className={" text-sm mx-2 w-36  "} >Next Scan Date</label>
                        <input name='nextscan' defaultValue={getFormattedDateForInput(formObj?.nextscan)} type='date' onChange={(e) => { handleInputChange(e); setNextScanDay(calculateDifference(formObj?.dayoneofcycle ?? formObj?.dayoneofcycle, e.target.value) + 1) }} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                        <i className='text-gray-400 text-sm ml-3'>{formObj?.nextscan && nextScanDay ? '~ Day-' + nextScanDay : ''}</i>
                    </div>
                </div>
                <div className=' w-2/12'>
                    <div className={'pt-2 flex items-center'}>
                        <label className={" text-sm mx-2  w-24"} >E2 pmol/L </label>
                        <input type="text" name='e2_dose' defaultValue={formObj?.e2_dose} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                    </div>
                    <div className={'pt-2 flex items-center'}>
                        <label className={" text-sm mx-2 w-24  "} >P4 nmol/L</label>
                        <input type='text' name='p4_dose' defaultValue={formObj?.p4_dose} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                    </div>
                    <div className={'pt-2 flex items-center'}>
                        <label className={" text-sm mx-2 w-24  "} >LH IU/L</label>
                        <input type='text' name='lh_dose' defaultValue={formObj?.lh_dose} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                    </div>

                </div>
                <div className=' w-2/12'>
                    <div className={'pt-2 flex items-center'}>
                        <label className={" text-sm mx-2  w-24"} >HCG IU/L </label>
                        <input type="text" name='hcg_dose' defaultValue={formObj?.hcg_dose} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                    </div>
                    <div className={'pt-2 flex items-center'}>
                        <label className={" text-sm mx-2 w-24  "} >FSH IU/L</label>
                        <input type='text' name='fsh_dose' defaultValue={formObj?.fsh_dose} onChange={(e) => handleInputChange(e)} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                    </div>

                </div>
                <div className=' w-5/12'>


                    <div className='flex text-left pt-1  w-full mr-0.5  mb-0.5 '>
                        <p className='text-sm mx-2  w-28  '>Notes/Comments</p>
                        <textarea id="txtcomments" rows="3" onChange={(e) => handleInputChange(e)} name="comments" className="  w-full text-sm px-1 py-0.5 border rounded accent-green-700  text-gray-700 focus:border-teal-500 focus:outline-none" placeholder="Add comments ..." />
                    </div>

                    <div className={theme.panelBg + 'mt-2 rounded flex justify-end items-center  pt-4'}>
                        <button className="bg-transparent mx-1 hover:bg-red-500 text-red-500 font-semibold text-sm hover:text-white  px-2 border border-red-300 hover:border-transparent rounded" > Cancel  </button>
                        <button className="bg-transparent ml-1 hover:bg-green-600 text-green-800 font-semibold text-sm hover:text-white  px-2 border border-green-500 hover:border-transparent rounded" onClick={() => { setShowModal(true); checkResults() }} >  Save</button>

                    </div>
                </div>
            </div>

            {/* -------------------- */}
            {ShowModal ? (
                <>
                    {/* <PinModal /> */}
                    <PinModal title="Saving Blood Result" successFunction={handleSubmit} />
                    {/* <div className="fixed z-10 inset-0 overflow-y-auto">
                            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                <div className="fixed inset-0 transition-opacity" aria-hidden="true" >
                                    <div className="absolute inset-0 bg-gray-500 opacity-75" ></div>
                                </div>
                                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true" ></span>
                                <Draggable>
                                    <div className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform sm:my-8 sm:align-middle sm:max-w-lg sm:w-full  ${showAlert ? 'animate-pulse  border border-red-300 ' : ''}`}
                                        role="dialog"
                                        aria-modal="true"
                                        aria-labelledby="modal-headline"
                                    >

                                        <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative`}>

                                            <svg className={'absolute top-2 right-2  shadow  font-semibold cursor-pointer'}
                                                onClick={() => { setShowModal(false); }} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
                                            </svg>
                                            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline" > Saving Bloods</h3>
                                            <div onMouseDown={(e) => e.stopPropagation()} className="my-3 ">
                                                <div className='w-full bg-gray-100 p-3 my-2  rounded-lg'>
                                                    <div className='flex items-center'>
                                                        <p className='leading-6 text-sbase ' >Enter your PIN</p>
                                                    </div>
                                                    <input type='password' name='PIN' placeholder='Enter PIN' autoFocus={true} maxLength={5} onChange={() => handlePinChange} className={'rounded w-full bg-white px-2 py-2 text-sm font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 outline-none '} />
                                                </div>
                                                {showAlert && (
                                                    <div className="buzz-alert text-red-600 text-sm">
                                                        Wrong password! Please try again.
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Draggable>
                            </div>
                        </div> */}

                </>
            ) : ''}


        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_POST, ApiCall_GET }

export default connect(mapStateToProps, mapDispatchToProps)(UpdateInCycleBlood)