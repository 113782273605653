import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../../AppContextProvider ';
import { formatTime, getArtStatClass, getCorrectDate, getCorrectDateForInput, getTextColor, getTimeOnly, getUniqueObjectsByKey, loadClinicDropdown, LookUpType, replaceEmptyObjectsWithNull } from '../../../../../common';
import { renderDataOnLoad } from '../../../../../Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiCall_GET } from '../../../../../Redux/Generic/action';


export const GenerateTheatreBooking = ({ ApiCall_GET }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const theme = useAppContext().themeDetails;
    var data = location.state.payload;
    var locationId = location.state.locationId;
    const [formData, setFormData] = useState(data);
    const [addOutside, setAddOutside] = useState(data);
    const [showRports, setShowRports] = useState(false);
    const [lookUpList, setLookUpList] = useState([]);

    const [searchPerson, setSearchPerson] = useState('');
    const [searchText, setsearchText] = useState('')
    const [personData, setPersonData] = useState('');

    const [clinicianlist, setClinicianlist] = useState([]);
    const [hospitalList, setHospitalList] = useState([])

    useEffect(() => {
        ApiCall_GET('UserManagment/GetLookUpListDetails/2', false).then(function (Payload) {
            setLookUpList(Payload)
        });

        ApiCall_GET('UserManagment/GetClinicianList/' + locationId, true, false).then(function (payload) {
            setClinicianlist(payload);
        });

        ApiCall_GET('UserManagment/GetClinicDetailsList/', true).then(function (payload) {
            setHospitalList(payload)
        });
    }, [])


    const handleFormInputChange = (e, index) => {
        const { name, value, id } = e.target;

        setAddOutside(prevData => ({
            ...prevData,
            [name]: value
        }));
    }
    const handleInputChange = (e, index) => {
        const { name, value, id } = e.target;

        // setFormData((prevFormData) => {
        //     let updatedData = { ...prevFormData };
        //     var a = prevFormData.TherterReport[index]

        // if (id == 'TherterReport') {
        //     a
        //     .map((item, i) =>
        //         i === index ? { ...item, [name]: value } : item
        //     );
        // } else if (id == 'OutsideProcedureReport') {
        //     prevFormData.OutsideProcedureReport.map((item, i) =>
        //         i === index ? { ...item, [name]: value } : item
        //     );
        // } else {
        //     prevFormData.OutsideProcedureReport?.map((item, i) =>
        //         i === index ? { ...item, [name]: value } : item
        //     );
        // }

        // return updatedData;
        // });
    };



    const handlesubmit = (e) => {

    }
    const handleSearchTextChange = (event) => {

        const { name, value, id } = event?.target || {};
        const searchValue = searchText?.[name];
        const isSearchValid =
            (name?.length > 1 && value?.trim()?.length > 1) ||
            (event.type === 'click' && searchValue?.length > 1);

        if (isSearchValid) {
            const svalue = event.type === 'click' ? searchValue : value;
            const encodedSvalue = encodeURIComponent(svalue?.trim() || '');
            ApiCall_GET(`TreatmentPlan/GetPatientAndPartnerData/${encodedSvalue}`, true)
                .then(function (payload) {
                    setSearchPerson(replaceEmptyObjectsWithNull(payload));
                })
                .catch(function (error) {
                    console.error("Error fetching data:", error); // Error handling
                });
        }
        // setsearchText({ search: event.target.value, type: type });
        setsearchText((prev) => ({ ...prev, [name]: value, 'id_': id, }));
    };

    const handleSearchResultSelect = (item, type) => {

        if (type === 0) {
            setPersonData('')
            var person1 = {
                personid: item.PatientPersonId,
                isPersonExist: true,
                firstname: item.PatientFirstName,
                middlename: item.PatientMiddleName || '',
                surname: item.PatientLastName,
                dob: item.PatientDOB,
                email: item.PatientEmail || '',
                gender: item.PatientGender || '',
                medicarenumber: item.PatientMedicareNumber || '',
                couplecode: item.couplecode || '',
                coupleD: item
            };

            setPersonData(person1);

        }
        setSearchPerson([]);
        setsearchText([]);
    };

    return (
        <div className={" bg-white  w-full h-[99%] scrollbar  scrollbar-thin overflow-auto"}>
            <div className=' p-2 pt-0 h-[60%] '>
                <div className={"p-1 flex gap-2 items-center justify-between  w-full "} >
                    <label className={theme.txt_color + '  font-bold cursor-pointer border-b-[3px] border-b-amber-500 '}>{formData?.ProcedureReport ? `Procedure Booking Form ~ ( ${getCorrectDate(formData.ProcedureReport[0]?.procdate)})` : `Theatre Booking Form ~ ( ${getCorrectDate(formData.TherterReport[0]?.procdate)})`}</label>
                    <button onClick={(e) => { navigate('/reports') }} className="flex items-center cursor-pointer justify-center text-gray-500 bg-yellow-400 border border-gray-300 focus:outline-none hover:text-black  hover:bg-yellow-600 hover:text-white focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2">
                        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.15716 0.607836C5.26072 0.711393 5.3125 0.834005 5.3125 0.975672C5.3125 1.11734 5.26072 1.23981 5.15716 1.34309L2.12293 4.37732L5.16752 7.42191C5.26417 7.51856 5.3125 7.63938 5.3125 7.78436C5.3125 7.92934 5.26072 8.05361 5.15716 8.15716C5.05361 8.26072 4.93099 8.3125 4.78933 8.3125C4.64766 8.3125 4.52519 8.26072 4.42191 8.15716L0.942381 4.66728C0.900958 4.62586 0.871548 4.58098 0.85415 4.53266C0.836753 4.48433 0.828192 4.43255 0.828468 4.37732C0.828468 4.32209 0.837167 4.27031 0.854564 4.22199C0.871962 4.17366 0.901234 4.12878 0.942381 4.08736L4.43226 0.59748C4.52892 0.500827 4.64808 0.4525 4.78974 0.4525C4.93141 0.4525 5.05388 0.504279 5.15716 0.607836Z" fill="#52575C" />
                        </svg>
                        <label className="pl-2 cursor-pointer">Back to Report</label>
                    </button>
                </div>
                <div className={theme.thumb + " " + theme.track + " shadow rounded  overflow-auto h-[89%] scrollbar  scrollbar-thin w-full "} style={{ width: "100%" }}>
                    {/* table header */}
                    <div className={"w-full flex py-1 sticky top-0 text-white rounded " + theme.navbar}>
                        <div style={{ width: "3%" }}
                            className={"flex item-center justify-center  py-1"} >
                            <p className="text-sm font-bold">Order</p>
                        </div>
                        <div style={{ width: "6%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Admit Time</p>
                        </div>
                        <div style={{ width: "6%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Procedure Date</p>
                        </div>
                        <div style={{ width: "6%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Procedure Time</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Procedure Type</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Duration</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Hospital</p>
                        </div>
                        <div style={{ width: "20%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Couple Details</p>
                        </div>
                        <div style={{ width: "10%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Clinician</p>
                        </div>
                        <div style={{ width: "10%" }} className={"py-1 flex item-center justify-center "}>
                            <p className="text-sm font-bold">Anaesthetic</p>
                        </div>
                        <div style={{ width: "15%" }} className={"py-1 flex item-center justify-center "}>
                            <p className="text-sm font-bold">Comment</p>
                        </div>
                    </div>
                    {/* display data */}

                    {formData &&
                        (formData?.ProcedureReport == null
                            ? formData.TherterReport
                            : formData?.ProcedureReport
                        )?.map((item, index) => (
                            // {formData && formData.ProcedureReport[0].Column1 !== null && formData.TherterReport.map((item, index) => (
                            <div className={" w-full  flex  bg-gray-100 mb-1 gap-x-2 rounded shadow border items-center " + theme.hoverBg}>
                                <div style={{ width: "3%" }} className={"flex item-center justify-center  "} >
                                    <p className="text-sm font-medium text-gray-600">{item.rownumber}</p>
                                </div>
                                <div style={{ width: "6%" }} className={"flex item-center justify-center"}>
                                    {/* <p className='text-sm font-medium text-gray-700'>{getTimeOnly(item.admittime)}</p> */}
                                    <input
                                        type="time"
                                        name="admittime"
                                        id={formData?.ProcedureReport == null ? 'TherterReport' : "ProcedureReport"}
                                        value={formatTime(item.admittime)}
                                        onChange={(e) => handleInputChange(e, index)}
                                        className="w-full text-sm px-1 py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none"
                                    />
                                </div>
                                <div style={{ width: "6%" }} className={"flex item-center justify-center"}>
                                    <input
                                        type="date"
                                        id={formData?.ProcedureReport == null ? 'TherterReport' : "ProcedureReport"}
                                        name="procdate"
                                        value={getCorrectDateForInput(item?.procdate)}
                                        onChange={(e) => handleInputChange(e)}
                                        className="w-full text-sm px-1 py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none" />
                                </div >
                                <div style={{ width: "6%" }} className={"flex item-center justify-center"}>
                                    <input
                                        type="time"
                                        id={formData?.ProcedureReport == null ? 'TherterReport' : "ProcedureReport"}
                                        name="procdate"
                                        value={formatTime(item.procdate)}
                                        onChange={(e) => handleInputChange(e)}
                                        className="w-full text-sm px-1  py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none" />
                                </div>
                                <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                                    <select name='proctype' value={item.proctype || ''} onChange={(e) => handleInputChange(e)} className="w-full text-sm px-1  py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none" >
                                        <option value="">~ Select Type ~</option>
                                        {lookUpList && lookUpList.length > 0 && lookUpList.filter((x) => x.LookUpTypeId === 17 && x.groupby.includes('epu')).map((lookup) => (
                                            <option key={lookup.procedureTypeID} value={lookup.procedureTypeID}>
                                                {lookup.typename}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                                <div style={{ width: "8%" }} className={"flex item-center justify-center gap-x-2"}>
                                    <select id={formData?.ProcedureReport == null ? 'TherterReport' : "ProcedureReport"} name="duration" value={item.duration || ""} onChange={(e) => handleInputChange(e, index)}
                                        className="w-1/3 text-sm px-0.5  py-0.5 flex items-center justify-center border  focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none">
                                        <option value="" disabled></option>
                                        {Array.from({ length: (90 - 5) / 5 + 1 }, (_, i) => i * 5 + 5).map((num) => (
                                            <option key={num} value={num}>{num} </option>
                                        ))}
                                    </select>
                                    <p className="text-sm font-medium text-gray-700">min/s</p>

                                </div >
                                <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                                    <select id={formData?.ProcedureReport == null ? 'TherterReport' : "ProcedureReport"} name='hospitalid' value={item?.hospitalid || ''} onChange={(e) => handleInputChange(e)}
                                        className="w-full text-sm px-1  py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none" >
                                        <option value={null} selected={!item?.phospitalid}>~ Select ~</option>
                                        {loadClinicDropdown(hospitalList, locationId, false, formData?.ClinicId)}
                                    </select>
                                </div >
                                <div style={{ width: "20%" }} className={"flex flex-col items-center justify-center"}>
                                    {/* <p className='text-sm font-medium text-gray-700'>{(item.medicarenumber)}</p> */}
                                    <p className='text-sm font-medium text-gray-700'>Couple Code -{item.couplecode}</p>
                                    <p className='text-sm font-medium text-gray-700'>{item.patient + ' ~  Dob :' + getCorrectDate(item.dob)}</p>
                                    <p className='text-sm font-medium text-gray-700'>Medicare ~{item.medicarenumber}</p>
                                    <p className='text-sm text-center font-medium text-gray-700'>{(item.contact)}</p>
                                </div >
                                <div style={{ width: "10%" }} className={"flex item-center justify-center"}>
                                    <select id={formData?.ProcedureReport == null ? 'TherterReport' : "ProcedureReport"} name='clinicianid' value={item?.clinicianid || ''} onChange={(e) => handleInputChange(e)}
                                        //  className={"w-full  text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none"}
                                        className="w-full text-sm px-1  py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none" >
                                        <option value="">~ Select Type ~</option>
                                        {clinicianlist && clinicianlist.map((item) => (
                                            <option value={item.ActualUserId}
                                                selected={item && item.clinicianid == item.ActualUserId}
                                            >{item?.username}</option>
                                        ))}
                                    </select>
                                </div>
                                <div style={{ width: "10%" }} className={"flex item-center justify-center flex-col "}>

                                    <select id={formData?.ProcedureReport == null ? 'TherterReport' : "ProcedureReport"} name='anaestheticid' value={item?.anaestheticid || ''} onChange={(e) => handleInputChange(e)}
                                        className="w-full text-sm px-1  py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none" >
                                        <option value="">~ Select Type ~</option>
                                        <option value='1'>General</option>
                                        <option value='2'>Local</option>
                                        <option value='3'>N/A</option>
                                        <option value='4'>Conscious Sedation</option>
                                    </select>
                                    <input id={formData?.ProcedureReport == null ? 'TherterReport' : "ProcedureReport"} type='text' name='anaesthetist' value={item?.anaesthetist} onChange={(e) => handleInputChange(e)} className='mt-1  py-0.5 w-full text-sm px-1 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                                </div>
                                <div style={{ width: "15%" }} className={"flex item-center justify-center "}>
                                    <textarea rows={item?.comment && Math.max(item?.comment.split('\n').length, 1)} type='text' name='pcomment' value={item?.comment || ''} className="focus:bg-white  py-0.5 border w-full rounded flex item-center justify-center text-sm text-gray-900 focus:border-teal-500 focus:outline-none" />
                                </div>
                            </div>
                        ))}
                </div>
            </div>

            <div className='p-2 h-[60%]'>
                <div className={" flex gap-2 items-center justify-between w-full  "} >
                    <label className={theme.txt_color + '  mb-2 font-bold cursor-pointer mx-2 border-b-[3px] border-b-amber-500 '}>Sperm Prep ~ ( {getCorrectDate(formData.TherterReport[0]?.procdate)} )</label>
                </div>
                <div className={theme.thumb + " " + theme.track + " shadow rounded  overflow-auto h-[89%] scrollbar  scrollbar-thin w-full "} style={{ width: "100%" }}>
                    {/* table header */}
                    <div className={"w-full flex py-1 sticky top-0 text-white rounded " + theme.navbar}>
                        <div style={{ width: "3%" }}
                            className={"flex item-center justify-center  py-1"} >
                            <p className="text-sm font-bold">Order</p>
                        </div>
                        <div style={{ width: "6%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Admit Time</p>
                        </div>
                        <div style={{ width: "6%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Procedure Date</p>
                        </div>
                        <div style={{ width: "6%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Procedure Time</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Sperm Prep Type</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Duration</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Hospital</p>
                        </div>
                        <div style={{ width: "20%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Couple Details</p>
                        </div>
                        <div style={{ width: "10%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Clinician</p>
                        </div>
                        <div style={{ width: "10%" }} className={"py-1 flex item-center justify-center "}>
                            <p className="text-sm font-bold">Anaesthetic</p>
                        </div>
                        <div style={{ width: "15%" }} className={"py-1 flex item-center justify-center "}>
                            <p className="text-sm font-bold">Comment</p>
                        </div>
                    </div>
                    {/* display data */}
                    {/* {formData && formData.TherterReport?.filter(item => item.donorcode).map((item, index) => ( */}
                    {formData && formData.TherterReport?.map((item, index) => (
                        <div className={" w-full  flex  bg-white  my-0.5 gap-x-2 rounded shadow border items-center " + theme.hoverBg}>
                            <div style={{ width: "3%" }} className={"flex item-center justify-center  "} >
                                <p className="text-sm font-medium text-gray-600">{item.rownumber}</p>
                            </div>
                            <div style={{ width: "6%" }} className={"flex item-center justify-center"}>
                                {/* <p className='text-sm font-medium text-gray-700'>{getTimeOnly(item.admittime)}</p> */}
                                <input
                                    type="time"
                                    name="padmittime"
                                    id='TherterReport'
                                    disabled
                                    value={formatTime(item.padmittime) || ''}
                                    onChange={(e) => handleInputChange(e, index)}
                                    className="w-full text-sm px-1  py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none"
                                />
                            </div>
                            <div style={{ width: "6%" }} className={"flex item-center justify-center"}>
                                {/* <p className='text-sm font-medium text-gray-700'>{getCorrectDate(item.procdate)}</p> */}
                                <input
                                    type="date"
                                    name="pprocdate"
                                    id='TherterReport'
                                    value={getCorrectDateForInput((item?.pprocdate || ''))}
                                    onChange={(e) => handleInputChange(e)}
                                    className="w-full text-sm px-1  py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none" />

                            </div >
                            <div style={{ width: "6%" }} className={"flex item-center justify-center"}>
                                {/* <p className='text-sm font-medium text-gray-700'>{getTimeOnly(item.procdate)}</p> */}
                                <input
                                    type="time"
                                    name="proctime"
                                    id='TherterReport'
                                    value={formatTime(item.pprocdate)}
                                    // value={getCorrectDateForInput(getTimeOnly(item?.procdate))}
                                    onChange={(e) => handleInputChange(e)}
                                    className="w-full text-sm px-1  py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none" />
                            </div>
                            <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                                {/* <p className='text-sm font-medium text-gray-700'>{(item.proctype)}</p> */}
                                <select id='TherterReport' name="spermprepid" value={item.spermprepid || ''} onChange={(e) => handleInputChange(e, index)}
                                    className="w-full text-sm px-1 py-0.5 border  focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none">
                                    <option value="">~ Select Type ~</option>
                                    {lookUpList && lookUpList
                                        .filter((item) => item.LookUpTypeId === 13)
                                        .map((lookup) => (
                                            <option key={lookup.spermprepid} value={lookup.spermprepid}>
                                                {lookup.spermprepname}
                                            </option>
                                        ))}
                                </select>

                            </div>
                            <div style={{ width: "8%" }} className={"flex item-center justify-center gap-x-2"}>
                                {/* <p className='text-sm font-medium text-gray-700'>{(item.duration)}</p> */}
                                <select id='TherterReport' name="pduration" value={item.pduration || ""} onChange={(e) => handleInputChange(e, index)}
                                    className="w-1/3  text-sm px-0.5  py-0.5 flex items-center justify-center border  focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none">
                                    <option value="" disabled></option>
                                    {Array.from({ length: (90 - 5) / 5 + 1 }, (_, i) => i * 5 + 5).map((num) => (
                                        <option key={num} value={num}>{num}</option>
                                    ))}
                                </select>
                                <p className="text-sm font-medium text-gray-700">min/s</p>

                            </div >
                            <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                                {/* <p className='text-sm font-medium text-gray-700'>{(item.hospitalid)}</p> */}
                                <select id='TherterReport' name='phospitalid' value={item?.phospitalid || ''} onChange={(e) => handleInputChange(e)}
                                    className="w-full text-sm px-1 py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none" >
                                    <option value={null} selected={!item?.phospitalid}>~ Select ~</option>
                                    {
                                        loadClinicDropdown(hospitalList, locationId, false, formData?.ClinicId)
                                    }
                                </select>

                            </div >
                            <div style={{ width: "20%" }} className={"flex flex-col items-center justify-center py-0.5"}>
                                <p className='text-sm font-medium text-gray-700'>Couple Code -{item.couplecode}</p>
                                <p className='text-sm font-medium text-gray-700'>Sperm Donor Code -{item.donorcode}</p>
                                <p className='text-sm font-medium text-gray-700'>{item.patient + ' ~  Dob :' + getCorrectDate(item.dob)}</p>
                                <p className='text-sm font-medium text-gray-700'>Medicare ~{item.medicarenumber}</p>
                                <p className='text-sm text-center font-medium text-gray-700'>{(item.contact)}</p>
                            </div >
                            <div style={{ width: "10%" }} className={"flex item-center justify-center"}>
                                {/* <p className='text-sm font-medium text-gray-700'>{item.clinicianid}</p> */}
                                <select id='TherterReport' name='pclinicianid' value={item?.pclinicianid || ''} onChange={(e) => handleInputChange(e)}
                                    //  className={"w-full  text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none"}
                                    className="w-full text-sm disabled px-1 py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none" >
                                    <option value="">~ Select Type ~</option>
                                    {clinicianlist && clinicianlist.map((item) => (
                                        <option value={item.ActualUserId}
                                            selected={item && item.pclinicianid == item.ActualUserId}
                                        >{item?.username}</option>
                                    ))}
                                </select>   </div>
                            <div style={{ width: "10%" }} className={"flex item-center justify-center flex-col "}>
                                {/* <p className='text-sm font-medium text-gray-700'>{(item.anaestheticid)}</p>
                                <p className='text-sm font-medium text-gray-700'>{(item.anaesthetist)}</p> */}

                                <select id='TherterReport' name='panaestheticid' value={item?.panaestheticid || ''} onChange={(e) => handleInputChange(e)}
                                    className="w-full text-sm px-1  py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none" >
                                    <option value="">~ Select Type ~</option>
                                    <option value='1'>General</option>
                                    <option value='2'>Local</option>
                                    <option value='3'>N/A</option>
                                    <option value='4'>Conscious Sedation</option>
                                </select>
                                <input type='text' name='panaesthetist' value={item?.panaesthetist} onChange={(e) => handleInputChange(e)} className='mt-1  py-0.5 w-full text-sm px-1 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />

                            </div>
                            <div style={{ width: "15%" }} className={"flex item-center justify-center "}>
                                <textarea rows={item?.pcomment && Math.max(item?.pcomment.split('\n').length, 1)} type='text' name='pcomment' value={item?.pcomment || ''} className="focus:bg-white px-2 py-0.5 border w-full rounded flex item-center justify-center text-sm text-gray-900 focus:border-teal-500 focus:outline-none" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='p-2 h-[60%]'>
                <div className={" flex gap-2 items-center justify-between  w-full "} >
                    <label className={theme.txt_color + '  mb-2 font-bold cursor-pointer mx-2 border-b-[3px] border-b-amber-500 '}>Outside of Cycle Procedure ~ ( {getCorrectDate(formData.TherterReport[0]?.procdate)} )</label>
                    <button onClick={(e) => { setShowRports(true) }}
                        className="flex items-center cursor-pointer justify-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:text-black mr-3 hover:bg-green-600 hover:text-white focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2">
                        {/* <FontAwesomeIcon icon={faEye} /> */}
                        <label className="cursor-pointer">+ ADD</label>
                    </button>
                </div>
                <div className={theme.thumb + " " + theme.track + " shadow rounded overflow-auto h-[89%] scrollbar  scrollbar-thin w-full "} style={{ width: "100%" }}>
                    {/* table header */}
                    <div className={"w-full flex py-1 sticky top-0 text-white rounded bg-[#5599ae] "}>
                        <div style={{ width: "3%" }}
                            className={"flex item-center justify-center  py-1"} >
                            <p className="text-sm font-bold">Order</p>
                        </div>
                        <div style={{ width: "6%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Admit Time</p>
                        </div>
                        <div style={{ width: "6%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Procedure Date</p>
                        </div>
                        <div style={{ width: "6%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Procedure Time</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Procedure Type</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Duration</p>
                        </div>
                        <div style={{ width: "8%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Hospital</p>
                        </div>
                        <div style={{ width: "20%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Couple Details</p>
                        </div>
                        <div style={{ width: "10%" }} className={"py-1 flex item-center justify-center"}>
                            <p className="text-sm font-bold">Clinician</p>
                        </div>
                        <div style={{ width: "10%" }} className={"py-1 flex item-center justify-center "}>
                            <p className="text-sm font-bold">Anaesthetic</p>
                        </div>
                        <div style={{ width: "15%" }} className={"py-1 flex item-center justify-center "}>
                            <p className="text-sm font-bold">Comment</p>
                        </div>
                    </div>
                    {/* display data */}
                    {formData && formData.OutsideProcedureReport?.map((item, index) => (
                        <div className={" w-full  flex  bg-gray-100 my-0.5 gap-x-2 rounded shadow border items-center " + theme.hoverBg}>
                            <div style={{ width: "3%" }} className={"flex item-center justify-center  "} >
                                <p className="text-sm font-medium text-gray-600">{item.rownumber}</p>
                            </div>
                            <div style={{ width: "6%" }} className={"flex item-center justify-center"}>
                                <input
                                    type="time"
                                    name="admittime"
                                    id='OutsideProcedureReport'
                                    value={formatTime(item.admittime)}
                                    onChange={(e) => handleInputChange(e, index)}
                                    className="w-full text-sm px-1 py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none"
                                />
                            </div>
                            <div style={{ width: "6%" }} className={"flex item-center justify-center"}>
                                {/* <p className='text-sm font-medium text-gray-700'>{getCorrectDate(item.procdate)}</p> */}
                                {/* <input type='date' name='procdate' value={formData?.procdate} onChange={(e) => handleInputChange(e)} className='w-full bg-red-300 text-sm px-2 flex item-center justify-center border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' /> */}
                                <input
                                    type="date"
                                    name="procdate"
                                    id='OutsideProcedureReport'
                                    value={getCorrectDateForInput((item?.procdate))}
                                    onChange={(e) => handleInputChange(e)}
                                    className="w-full text-sm px-1  py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none" />

                            </div >
                            <div style={{ width: "6%" }} className={"flex item-center justify-center  "}>
                                <input
                                    type="time"
                                    name="procdate"
                                    value={formatTime(item.admittime)}
                                    id='OutsideProcedureReport'
                                    // value={getCorrectDateForInput(getTimeOnly(item?.procdate))}
                                    onChange={(e) => handleInputChange(e)}
                                    className="w-full text-sm px-1  py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none" />
                            </div>
                            <div style={{ width: "8%" }} className={"flex item-center justify-center"}>
                                <select name="proctype" id='OutsideProcedureReport' value={item.proctype || ''} onChange={(e) => handleInputChange(e, index)}
                                    className="w-full text-sm px-1  py-0.5 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none">
                                    <option value="">~ Select Type ~</option>
                                    {lookUpList && lookUpList
                                        .filter((item) => item.LookUpTypeId === 12)
                                        .map((lookup) => (
                                            <option key={lookup.outsideCycleProcID} value={lookup.outsideCycleProcID}>
                                                {lookup.outsideCycleProcName}
                                            </option>
                                        ))}
                                </select>
                            </div>

                            <div style={{ width: "8%" }} className="flex items-center justify-center gap-x-2">
                                <select id='OutsideProcedureReport' name="duration" value={item.duration || ""} onChange={(e) => handleInputChange(e, index)}
                                    className="w-1/3 text-sm px-0.5  py-0.5 flex items-center justify-center border  focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none">
                                    <option value="" disabled></option>
                                    {Array.from({ length: (90 - 5) / 5 + 1 }, (_, i) => i * 5 + 5).map((num) => (
                                        <option key={num} value={num}>{num}</option>
                                    ))}
                                </select>
                                <p className="text-sm font-medium text-gray-700">min/s</p>
                            </div>
                            <div style={{ width: "8%" }} className={"flex flex-col item-center justify-center"}>
                                {/* <p className='text-sm font-medium text-gray-700'>{(item.hospitalid)}</p> */}
                                <select id='OutsideProcedureReport' name='hospitalid' value={item?.hospitalid || ''} onChange={(e) => handleInputChange(e)}
                                    className="w-full text-sm  py-0.5 px-1 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none" >
                                    <option value={null} selected={!item?.hospitalid}>~ Select ~</option>
                                    {loadClinicDropdown(hospitalList, locationId, false, formData?.ClinicId)}
                                </select>

                            </div >
                            <div style={{ width: "20%" }} className={"flex flex-col items-center justify-center"}>
                                {/* <p className='text-sm font-medium text-gray-700'>{(item.medicarenumber)}</p> */}
                                <p className='text-sm font-medium text-gray-700'>{item.patient + ' ~  Dob :' + getCorrectDate(item.dob)}</p>
                                <p className='text-sm font-medium text-gray-700'>Medicare ~{item.medicarenumber}</p>
                                <p className='text-sm text-center font-medium text-gray-700'>{(item.contact)}</p>
                            </div>
                            <div style={{ width: "10%" }} className={"flex item-center justify-center"}>
                                {/* <p className='text-sm font-medium text-gray-700'>{item.clinicianid}</p> */}
                                <select id='OutsideProcedureReport' name='clinicianid' value={item?.clinicianid || ''} onChange={(e) => handleInputChange(e)}
                                    //  className={"w-full  text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none"}
                                    className="w-full text-sm px-1 py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none" >
                                    <option value="">~ Select Type ~</option>
                                    {clinicianlist && clinicianlist.map((item) => (
                                        <option value={item.ActualUserId}
                                            selected={item && item.clinicianid == item.ActualUserId}
                                        >{item?.username}</option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ width: "10%" }} className={"flex item-center justify-center flex-col "}>
                                <select id='OutsideProcedureReport' name='anaestheticid' value={item?.anaestheticid || ''} onChange={(e) => handleInputChange(e)}
                                    className="w-full text-sm px-1  py-0.5 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none" >
                                    <option value="">~ Select Type ~</option>
                                    <option value='1'>General</option>
                                    <option value='2'>Local</option>
                                    <option value='3'>N/A</option>
                                    <option value='4'>Conscious Sedation</option>
                                </select>
                                <input type='text' name='anaesthetist' value={item?.anaesthetist} onChange={(e) => handleInputChange(e)} className='mt-1 w-full  py-0.5 text-sm px-1 border focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                            </div>
                            <div style={{ width: "15%" }} className={"flex item-center justify-center "}>
                                <textarea rows={item?.comment && Math.max(item?.comment.split('\n').length, 1)} type='text' name='pcomment' value={item?.comment || ''} className="focus:bg-white px-1 py-0.5 border w-full rounded flex item-center justify-center text-sm text-gray-900 focus:border-teal-500 focus:outline-none" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {showRports && (
                <div className="fixed z-50 inset-0 overflow-y-auto " >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div style={{ backdropFilter: 'blur(7px)' }} className="fixed inset-0 transition-opacity filter" aria-hidden="true" >
                            <div className="absolute inset-0 bg-gray-700 opacity-70  " ></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl " aria-hidden="true" ></span>
                        {/* <Draggable> */}
                        <div className={`sm:my-8   sm:max-w-lg sm:w-full filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`} role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                            <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative  `}>
                                <svg className={' absolute top-2 right-2  cursor-pointer shadow  font-semibold  '}
                                    onClick={() => setShowRports(false)}
                                    width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
                                </svg>
                                <h3 className={theme.txt_color + " text-xl text-center mb-6 leading-6 font-bold text-gray-900"} id="modal-headline" >Add Outside of Cycle Procedure</h3>
                                <div className=' w-full items-center bg-gray-100 p-3   rounded-t mb-b' onMouseDown={(e) => e.stopPropagation()}>
                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer '}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Admit Time</p>
                                        <input type='time' name='admit_new' value={addOutside?.admit_new} onChange={(e) => handleFormInputChange(e)} className='w-1/2 pl-3 text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                                    </div>
                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer '}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Procedure Time</p>
                                        <input type='time' name='proctime_new' value={addOutside?.proctime_new} onChange={(e) => handleFormInputChange(e)} className='w-1/2 pl-3 text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                                    </div>
                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer '}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Hospital</p>
                                        <select name='hospital_new' value={addOutside?.hospital_new || ''} onChange={(e) => handleFormInputChange(e)} className={"w-1/2 pl-3 text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none"}>
                                            {/* <option value="">~ Select Type</option> */}
                                            <option value={null} selected={!addOutside?.hospitalid}>~ Select ~</option>
                                            {loadClinicDropdown(hospitalList, locationId, false, addOutside?.ClinicId)}
                                        </select>
                                    </div>
                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer '}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Patient </p>
                                        <input type='text' name='patient' placeholder='Type three (3) or more character/symbols' value={searchText.patient}
                                            onChange={(e) => handleSearchTextChange(e)}
                                            onKeyDown={(e) => handleSearchTextChange(e)} className='w-1/2 pl-3 text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />


                                        {searchPerson && searchText.id_ == 0 && (
                                            <ul className={`${theme.thumb} ${theme.track} ${theme.thin} absolute top-[100px] left-4 bg-white  mt-1 shadow-md max-h-48 w-[50%]  overflow-y-scroll scrollbar scrollbar-thin`}>
                                                {searchPerson?.map((item, index) => (
                                                    <li key={index}
                                                        className="px-2 py-1 hover:bg-gray-200 cursor-pointer text-gray-700 w-full text-xss "
                                                        onClick={() => { handleSearchResultSelect(item, 0) }}>
                                                        {/* {item?.PatientLastName + ', ' + item.PatientFirstName + ', ' + item.PatientDOB + ', ' + item.Couplecode + ', ' + item.UserName + ', '} */}
                                                        {[
                                                            item?.PatientLastName,
                                                            item?.PatientFirstName,
                                                            getCorrectDate(item?.PatientDOB),
                                                            item?.couplecode,
                                                            item?.UserName
                                                        ].filter(Boolean).join(', ')}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>

                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer '}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Clinician</p>
                                        <select name='clinician_new' value={addOutside?.clinician_new || ''} onChange={(e) => handleFormInputChange(e)} className={"w-1/2 pl-3 text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none"}>
                                            <option value="">~ Select Type ~</option>
                                            {clinicianlist && clinicianlist.map((item) => (
                                                <option value={item.ActualUserId}
                                                    selected={item && item.clinicianid == item.ActualUserId}
                                                >{item?.username}</option>
                                            ))}
                                        </select></div>
                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer '}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Procedure Type</p>
                                        <select name='outsidecycle_new' value={addOutside?.outsidecycle_new || ''} onChange={(e) => handleFormInputChange(e)} className={"w-1/2 pl-3 text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none"}>
                                            <option value="">~ Select Type ~</option>
                                            {lookUpList && lookUpList
                                                .filter((item) => item.LookUpTypeId === 12)
                                                .map((lookup) => (
                                                    <option key={lookup.outsideCycleProcID} value={lookup.outsideCycleProcID}>
                                                        {lookup.outsideCycleProcName}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer '}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Anaesthetic</p>
                                        <select name='anaesthetic_new' value={addOutside?.anaesthetic_new || ''} onChange={(e) => handleFormInputChange(e)} className={"w-1/2 pl-3 text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none"}>
                                            <option value="">~ Select Type ~</option>
                                            <option value='1' >Genral</option>
                                            <option value='2' >Local</option>
                                            <option value='3'>N/A</option>
                                            <option value='4'>Conscious Sedation</option>
                                        </select>  </div>
                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer ' + theme.hoverBg}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Anaesthetist</p>
                                        <input type='text' name='anaesthetist_new' value={addOutside?.anaesthetist_new} onChange={(e) => handleFormInputChange(e)} className='w-1/2 pl-3 text-sm px-1 py-1 border border-transparent focus:bg-white rounded text-gray-900 focus:border-teal-500 focus:outline-none' />
                                    </div>
                                    <div className={' w-full text-left px-4 p-1 flex   cursor-pointer ' + theme.hoverBg}>
                                        <p className=' cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Comment</p>

                                        <textarea rows="3" type='text' name='comment_new' value={addOutside?.comment_new} onChange={(e) => handleFormInputChange(e)} className="focus:bg-white border w-1/2 rounded py-1 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none" />
                                    </div>
                                </div>
                                <div className=' py-2 rounded flex justify-end border px-6'>
                                    <button className="bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded" onClick={() => setShowRports(false)}> Cancel</button>
                                    <button className="bg-transparent  cursor-pointer ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded">  Generate Form</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }

        </div >
    )
}

const mapStateToProps = (state) => ({})



const mapDispatchToProps = { ApiCall_GET }

export default connect(mapStateToProps, mapDispatchToProps)(GenerateTheatreBooking)