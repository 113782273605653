import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import logo from '../../../../../Assets/images/white.png';
import cfc from '../../../../../Assets/images/cf-logo-banner-2018.png';
import rfc from '../../../../../Assets/images/rf-logo-banner-2016.png';
import fsf from '../../../../../Assets/images/fsf-logo-banner-2017.png';

import { getUserInformation } from '../../../../../common';
import { useAppContext } from '../../../../../AppContextProvider ';

export const OutcomePositivePrint = ({ artid, artDetails, htmlContent, showPreview }) => {

    const user = getUserInformation()
    const theme = useAppContext().themeDetails;
    const [currentTime, setCurrentTime] = useState(new Date());

    return (
        <div id="divPrint" className="absolute flex min-h-screen w-full  p-2 delay-900 duration-700  ">
            {/* Header */}
            <div className="relative w-full   bg-white p-3">
                <div className={` ` + 'flex justify-between   mb-2  p-2'} >

                    <div className='flex items-center justify-end '>
                        {htmlContent.brandCode === "CFC" &&
                            < img src={cfc} className="logoCFC" alt="img" width='250px' />
                        }
                        {htmlContent.brandCode === "RF" &&
                            < img src={rfc} className="logoRFC" alt="img" width='250px' />
                        }
                        {htmlContent.brandCode === "FSF" &&
                            < img src={fsf} className="logoFSF" alt="img" width='250px' height='70px' />
                        }
                        <p className={`dot-${user?.BrandCode?.toUpperCase()} `} style={{ backgroundColor: `#${user?.ChartingColor}` }}></p>
                        <p className='  text-sm ml-1'>{user?.LocationName}</p>
                    </div>
                    <div className='flex justify-end  items-center '>
                        <label className=' font-semibold text-sm'>{currentTime.toLocaleDateString(undefined, { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' })}</label>
                        <label className='pl-1 w-24 font-semibold'>{currentTime.toLocaleTimeString()}</label>
                    </div>

                </div>
                <main className=''>
                    {/* patient info */}
                    <div className=" w-full   bg-white p-3">
                        <p className='text-base font-semibold'>{htmlContent?.add1}</p>
                        <p className='text-base font-semibold '>{htmlContent?.add2}</p>
                        <p className='text-base font-semibold'>{htmlContent?.add3}</p>
                        <p className='text-base font-semibold'>{htmlContent?.add4}</p>
                        <p className='text-base font-semibold mb-3 '>{htmlContent?.city} {htmlContent?.state} {htmlContent?.postcode}</p>
                        {/* <p className="text-base mb-2 font-normal">{htmlContent?.RecipientName || ((htmlContent?.pfirstname) || htmlContent?.pfirstname.toUpperCase() === "NO PARTNER" ? htmlContent?.firstname : `${htmlContent?.firstname} and ${htmlContent?.pfirstname}`)}</p> */}
                        <p className='text-base font-semibold my-4'>Dear {htmlContent.firstname} And {htmlContent.pfirstname}</p>
                        <p className='text-basee mb-3 font-normal'>Congratulations on your pregnancy. You will, no doubt, have many questions about your pregnancy. The aim of this letter is to guide you on to the next step you need to take.</p>
                        <p className="text-base mb-3">{htmlContent?.locationid === 1 || htmlContent?.locationid === 5 || htmlContent?.locationid === 51 ? null : `At ${htmlContent?.brandCode}, we have developed an early pregnancy seminar to provide information and support through the first trimester of pregnancy. Please contact City Fertility Centre reception to reserve a place.`}</p>
                        <p className='text-base mb-3 '>It is important that you contact your IVF Clinician’s rooms to arrange your initial pregnancy consultation; this will include your first ultrasound to confirm your pregnancy. This scan usually occurs<b> 2-3 weeks</b> after your positive pregnancy blood test or at your doctor’s discretion.</p>
                        <p className='text-base mb-3 '>It is important that you do not cease any medication that you have been instructed to take until advised by your treating doctor. Panadol and Panadeine are safe to be taken in the event of any mild pain, discomfort, and fever.</p>
                        <p className='text-base mb-3 '>At {htmlContent.brandCode}, we like to remain involved in your pregnancy journey. As part of this involvement, we will be enquiring about your pregnancy progress at approximately 14 and 32 weeks of pregnancy via a phone call and/or letter or Email."</p>
                        {/* <p className='text-base mb-2 '>{htmlContent?.LetterContent?.FollowUpDetails}</p> */}
                        <p className='text-base mb-3 '>Once again, congratulations on your pregnancy and please do not hesitate to contact us if we can be of any further assistance.</p>
                        <p className='text-base mb-4  font-semibold'>Kind Regards</p>
                        <p className='text-base  font-semibold '>IVF Nurse Coordinator</p>
                        {/* <p className='text-base mb-2 '>{htmlContent?.brandCode}</p> */}
                        {htmlContent.brandCode === "CFC" &&
                            <p className='text-base font-semibold mb-2 '>City Fertility Centre</p>
                        }
                        {htmlContent.brandCode === "RF" &&
                            <p className='text-base font-semibold mb-2 '>Rainbow Fertility Centre</p>
                        }
                        {htmlContent.brandCode === "FSF" &&
                            <p className='text-base font-semibold mb-2 '>First Step Fertility</p>
                        }
                    </div >
                </main >
                <footer className='flex mt-3 p-2 '>
                    <div className='w-full flex justify-between items-center text-gray-400'>
                        <p className='text-sm  '>{htmlContent?.locationid != 1 && htmlContent?.locationid != 5 && htmlContent?.locationid != 51 ? "N46 Melb GC SA S" : "N46 Bris SB"}</p>
                        <div className='flex justify-end  text-sm items-center '>
                            <label className=' font-semibold'>{currentTime.toLocaleDateString(undefined, { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' })}</label>
                            <label className='pl-1 '>{currentTime.toLocaleTimeString()}</label>
                        </div>
                        <p className='text-sm font-semibold '>Approved by National Nursing Manager</p>
                    </div>
                </footer>
            </div >
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OutcomePositivePrint)