import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../../AppContextProvider ';
import { ApiCall_POST, ApiCall_GET } from '../../../Redux/Generic/action';
import { connect } from 'react-redux'
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { differenceInYears } from 'date-fns';
import { getClinicianBgColor, getCorrectDate, getCorrectDateForInput, getCycleTypeColor, getFormattedDateForInput, getTextColor, getUserInformation, loadClinicDropdown, replaceEmptyObjectsWithNull, returnNullIfEmpty, SelectedPages, showNotificationMessage } from '../../../common';
import { useNavigate } from 'react-router-dom';

function CreatePerson({ ApiCall_POST, ApiCall_GET }) {
    const navigate = useNavigate();

    const theme = useAppContext().themeDetails;
    const initialFormData = {
        firstname: "",
        surname: "",
        middlename: "",
        dob: null,
        email: "",
        personsex: null,
        medicarenumber: null
    }
    const [ShowModal, setShowModal] = useState(false)
    const [searchPerson, setSearchPerson] = useState('');
    const [searchText, setsearchText] = useState('')
    // First person
    // set data
    const [personData, setPersonData] = useState(initialFormData);
    const [isexist, setisexist] = useState(false);
    const [errors, setErrors] = useState({});

    // Second person
    const [secondPersonPData, setsecondPersonPData] = useState(initialFormData);
    const [secondPersonIsexist, setsecondPersonIsexist] = useState(false);
    const [errorsP2, setErrorsP2] = useState({});
    //  show Buttons
    const [firstPerson, setFirstPerson] = useState(false);
    const [secondPerson, setSecondPerson] = useState(false);
    const [isChecked, setIsChecked] = useState(true);

    const handleTabClick1 = () => {
        setFirstPerson(true);
    };
    const handleSecondPerson = () => {
        setSecondPerson(true);
    };
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        if (isChecked == false) {
            setSecondPerson(false)
            setsecondPersonPData(initialFormData)
        }
        // setIsButtonDisabled(!isChecked); // Enable button when checkbox is unchecked
    };
    const handleInputOne = (e, val) => {
        // setErrors(null)
        // setisexist(null)
        const { name, value } = e.target;

        setErrors(prevState => ({
            ...prevState,
            [name]: null,
        }));

        setPersonData(prevState => ({
            ...prevState,
            [name]: value,
            val
        }));
    };
    const handleCancelFirst = (e) => {

        e.preventDefault()
        setPersonData(initialFormData);
        setFirstPerson(false);

        setsecondPersonPData(initialFormData);
        setSecondPerson(false);
        setErrors({});
        setErrorsP2({});
        setisexist(false);
        setsecondPersonIsexist(false);
    }


    const handleInputChange = (e, val) => {
        // setsecondPersonIsexist(null)
        // setErrorsP2(null)
        const { name, value } = e.target;
        setErrorsP2(prevState => ({
            ...prevState,
            [name]: null,
        }));
        setsecondPersonPData(prevState => ({
            ...prevState,
            [name]: value,
            val
        }));
    };

    const EditPerson = () => {
        const updatedPersonData = { ...personData, status: 'edit' }; // Or any other relevant key-value pair

        setPersonData(updatedPersonData);

        ApiCall_POST('Patient/SavePerson', updatedPersonData, true).then(function (payload) {
            payload = returnNullIfEmpty(payload);

            Swal.fire({
                icon: 'success',
                title: 'Person has been Edited.',
                showConfirmButton: false,
                timer: 1500
            });

            // setisexist(false);
        }).catch(function (error) {
            Swal.fire({
                icon: 'error',
                title: 'An error occurred.',
                text: 'Please try again later.',
                showConfirmButton: true,
            });
        });
    }


    const userSchema = Yup.object().shape({
        firstname: Yup.string().required("First Name Is Required!"),
        surname: Yup.string().required("Last Name Is Required!"),
        email: Yup.string().email("Invalid Email Format!"),
        dob: Yup.date().test(
            'is 14',
            'You must be at least 14 years old',
            value => differenceInYears(new Date(), new Date(value)) >= 14
        )
            .required("DOB Is Required!")
    });



    const handetcheq = async (e) => {

        e.preventDefault();
        let onlyCreateRelation = false;
        personData.OnlyCreateRelation = onlyCreateRelation;
        try {
            const errorsP1 = {};
            const errorsP2 = {};

            // Validate the first person's data
            try {
                await userSchema.validate(personData, { abortEarly: false });
            } catch (error) {
                error.inner.forEach(err => {
                    errorsP1[err.path] = err.message;
                });
            }
            // Validate the second person's data if not checked
            if (!isChecked) {
                try {
                    await userSchema.validate(secondPersonPData, { abortEarly: false });
                } catch (error) {
                    error.inner.forEach(err => {
                        errorsP2[err.path] = err.message;
                    });
                }
            }

            // Set errors if any
            setErrors(errorsP1);
            if (!isChecked) {
                setErrorsP2(errorsP2);
            }

            // Check if there are any errors before proceeding
            if (Object.keys(errorsP1).length > 0 || (!isChecked && Object.keys(errorsP2).length > 0)) {
                return;
            }

            // Ensure different person data
            if (personData.firstname === secondPersonPData.firstname && personData.surname === secondPersonPData.surname && personData.dob === secondPersonPData.dob) {
                showNotificationMessage('warning', 'You entered same Person!', false);
                return;
            }
            // Prepare person details
            var personDetails = [personData];
            if (!isChecked) {
                personDetails.push(secondPersonPData);
            }else{

                var noCouple = {

                    firstname: 'NO PARTNER',
                    surname: 'NO PARTNER',
                    middlename: 'NO PARTNER',
                    dob: getCorrectDate('2001-01-01 00:00:00'),
                    email: 'NO PARTNER',
                    personsex: Number(personData.personsex) > 0  ?  (personData.personsex === 1 ? 2 : 1) : null,
                    medicarenumber: '99 999 999'
                }
                personDetails.push(noCouple);
            }

            var isRediercet = false;
            // API call
            ApiCall_POST('Patient/SavePerson', personDetails, true).then(function (payload) {
                var notification = '';
                // if (isChecked) {
                //     notification += 'Person has been coupled with No Partner. \n';
                // }
                
                if (!isChecked && payload.Person1.coupleD?.CoupleCode === payload.Person2.coupleD?.CoupleCode) {

                    notification += 'Both person are coupled. \n';
                }

                if (!isChecked && (payload.Person1?.coupleD?.CoupleCode !== payload.Person2?.coupleD?.CoupleCode)) {
                    // notification += 'Do you wanna create new relationship \n';
                    setShowModal(true)
                }
                // if (payload.Person1?.coupleD && payload.Person2?.coupleD) {
                //     notification += 'Both person are already in relationship.\n';
                // }

                if (payload.Person1 && payload?.Person1?.isPersonExist) {
                    notification += 'Patient already exists! \n';
                }
                if (payload.Person1?.medicarenumber !== personData.medicarenumber) {
                    showNotificationMessage('info', 'Medicare number has been updated.\n', true);
                }

                if (!isChecked && payload.Person2 && payload?.Person2?.isPersonExist) {
                    notification += ' Partner already exists! \n';
                }
                if (!isChecked && payload.Person2?.medicarenumber !== secondPersonPData.medicarenumber) {
                    showNotificationMessage('info', 'Medicare number has been updated.\n', true);
                }

                // payload?.Person1?.isPersonExist === true && setisexist(payload.Person1, payload?.Person1?.isPersonExist === true);
                setisexist(payload.Person1, payload?.Person1?.isPersonExist === true);
                !isChecked && setsecondPersonIsexist(payload?.Person2, payload?.Person2?.isPersonExist === true);

                setPersonData(payload.Person1 || {});
                !isChecked && setsecondPersonPData(payload.Person2 || {});

                notification && showNotificationMessage('warning', notification, true);

                var successNotification = '';

                if (!(payload.Person1?.isPersonExist)) {
                    successNotification += ' Patient has been saved.';
                }
                if (!isChecked && !(payload.Person2?.isPersonExist)) {
                    successNotification += '\n Partner has been saved.';
                }
                if (!payload.Person1?.isPersonExist && !(payload.Person2?.isPersonExist)) {
                    isRediercet = true
                    localStorage.setItem('selectedPage', SelectedPages.CoupleInfo);
                    localStorage.setItem('coupleId', payload.coupleD.CoupleId);
                    successNotification += ' Relationship Created \n';
                }

                successNotification && showNotificationMessage('success', successNotification, true);
                isRediercet && navigate('/coupleDetails', { state: { id: payload.coupleD.CoupleId } })
            });

        } catch (error) {
            // Catching other potential errorsassdas
            console.error("Unexpected Error:", error);
        }
    }
    const handelRelation = async (e) => {
        e.preventDefault();
        ApiCall_POST('Patient/CreateRelationship/' + personData.personid + '/' + secondPersonPData.personid, true, true).then(function (payload) {
            showNotificationMessage('success', 'Relationship Created!.', false);
            localStorage.setItem('selectedPage', SelectedPages.CoupleInfo);
            localStorage.setItem('coupleId', payload.coupleD.CoupleId);
            navigate('/coupleDetails', { state: { id: payload.coupleD.CoupleId } })
            setShowModal(false)
            // navigate('/coupledetails', payload)
        });
    }
    const handleSearchTextChange = (event) => {

        const { name, value, id } = event?.target || {};
        const searchValue = searchText?.[name];
        const isSearchValid =
            (name?.length > 1 && value?.trim()?.length > 1) ||
            (event.type === 'click' && searchValue?.length > 1);

        if (isSearchValid) {
            const svalue = event.type === 'click' ? searchValue : value;
            const encodedSvalue = encodeURIComponent(svalue?.trim() || '');
            ApiCall_GET(`TreatmentPlan/GetPatientAndPartnerData/${encodedSvalue}`, true)
                .then(function (payload) {
                    setSearchPerson(replaceEmptyObjectsWithNull(payload));
                })
                .catch(function (error) {
                    console.error("Error fetching data:", error); // Error handling
                });
        }
        // setsearchText({ search: event.target.value, type: type });
        setsearchText((prev) => ({ ...prev, [name]: value, 'id_': id, }));
    };

    const handleSearchResultSelect = (item, type) => {

        if (type === 0) {
            setPersonData('')
            var person1 = {
                personid: item.PatientPersonId,
                isPersonExist: true,
                firstname: item.PatientFirstName,
                middlename: item.PatientMiddleName || '',
                surname: item.PatientLastName,
                dob: item.PatientDOB,
                email: item.PatientEmail || '',
                personsex: item.PatientGender || '',
                medicarenumber: item.PatientMedicareNumber || '',
                couplecode: item.couplecode || '',
                coupleD: item
            };

            setPersonData(person1);
            // setErrors(item);
        } else if (type === 1) {
            setsecondPersonPData('')
            var person2 = {
                personid: item.PartnerPersonId,
                isPersonExist: true,
                firstname: item.PartnerFirstName,
                middlename: item.PartnerMiddleName || '',
                surname: item.PartnerLastName,
                dob: item.PartnerDOB,
                email: item.PartnerEmail,
                personsex: item.PartnerGender || '',
                medicarenumber: item.PartnerMedicareNumber || '',
                couplecode: item.couplecode || '',
                coupleD: item

            };
            setsecondPersonPData(person2);
            // setErrorsP2(item);
        }
        setSearchPerson([]);
        setsearchText([]);
    };

    return (
        <div className='w-full  h-full p-1 '>
            <div className={'w-full h-full p-2 bg-white rounded '}>
                <div className={theme.bg_Light + ' flex items-between p-0.5  pl-2 rounded shadow '}>
                    <div className='w-2/5 text-left'>
                        <label className={theme.txt_color + ' pl-2text-base font-medium '}>Create Relationship</label>
                        <label className={theme.txt_color + ' ml-2 text-base font-medium '}> </label>
                    </div>
                    <div className='w-3/5 text-right flex justify-end'>
                        <button
                            onClick={() => navigate('/home',
                                //  { state: { id: coupleid } }
                            )}
                            className="flex items-center cursor-pointer justify-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:text-black hover:bg-yellow-400 focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2"
                        >
                            <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.15716 0.607836C5.26072 0.711393 5.3125 0.834005 5.3125 0.975672C5.3125 1.11734 5.26072 1.23981 5.15716 1.34309L2.12293 4.37732L5.16752 7.42191C5.26417 7.51856 5.3125 7.63938 5.3125 7.78436C5.3125 7.92934 5.26072 8.05361 5.15716 8.15716C5.05361 8.26072 4.93099 8.3125 4.78933 8.3125C4.64766 8.3125 4.52519 8.26072 4.42191 8.15716L0.942381 4.66728C0.900958 4.62586 0.871548 4.58098 0.85415 4.53266C0.836753 4.48433 0.828192 4.43255 0.828468 4.37732C0.828468 4.32209 0.837167 4.27031 0.854564 4.22199C0.871962 4.17366 0.901234 4.12878 0.942381 4.08736L4.43226 0.59748C4.52892 0.500827 4.64808 0.4525 4.78974 0.4525C4.93141 0.4525 5.05388 0.504279 5.15716 0.607836Z" fill="#52575C" />
                            </svg>
                            <label className='pl-2 cursor-pointer'> Back to Home</label>
                        </button>

                    </div>
                </div>
                {/* Tab bar Section */}
                {/* <div className={' p-2 flex items-center bg-white'}>
                </div> */}
                {/* Tab Body  */}
                <div className='flex gap-2 h-[88%]'>
                    <div className={theme.thumb + ' ' + theme.track + ` h-full bg-gray-50 relative  rounded shadow  overflow-y-auto w-1/2 p-2  scrollbar-thin `} >
                        <div className=' font-medium bg-white   bg-gray-100 '>
                            <div className={' flex justify-between text-white text-left px-2 py-1 bg-[#5599ae]  rounded-t'}> <p className=' font-medium'>Patient Information</p></div>
                            {!firstPerson ?
                                <div className='flex justify-center  items-center h-52'>
                                    <svg onClick={handleTabClick1}
                                        // color="success"
                                        className={"hover:scale-105 shadow duration-500 cursor-pointer"}
                                        width="61" height="61" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M45.3333 0H5.66667C4.16377 0 2.72243 0.597022 1.65973 1.65973C0.597022 2.72243 0 4.16377 0 5.66667V45.3333C0 46.8362 0.597022 48.2776 1.65973 49.3403C2.72243 50.403 4.16377 51 5.66667 51H45.3333C48.45 51 51 48.45 51 45.3333V5.66667C51 2.55 48.45 0 45.3333 0ZM36.8333 28.3333H28.3333V36.8333C28.3333 38.3917 27.0583 39.6667 25.5 39.6667C23.9417 39.6667 22.6667 38.3917 22.6667 36.8333V28.3333H14.1667C12.6083 28.3333 11.3333 27.0583 11.3333 25.5C11.3333 23.9417 12.6083 22.6667 14.1667 22.6667H22.6667V14.1667C22.6667 12.6083 23.9417 11.3333 25.5 11.3333C27.0583 11.3333 28.3333 12.6083 28.3333 14.1667V22.6667H36.8333C38.3917 22.6667 39.6667 23.9417 39.6667 25.5C39.6667 27.0583 38.3917 28.3333 36.8333 28.3333Z" fill={theme.iconColor} />
                                    </svg>

                                </div>
                                :
                                < form className={theme.tableBg + ` w-full flex flex-wrap p-2  border  rounded-b-md`}>
                                    {isexist?.isPersonExist > 0 && <div id="alert-4" className="flex justify-center w-full self-end shadow p-2 mb-1  mr-0 text-[#664d03] border rounded-lg bg-red-100  dark:text-red-300" role="alert">
                                        <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#9b1c1c" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                        </svg>
                                        <span className="sr-only">Info</span>
                                        <div className="ms-3 text-sm  font-medium text-[#9b1c1c]">
                                            <i> ~Patient Already Exist ~</i>
                                        </div>
                                    </div>}
                                    {/* =========================== */}
                                    <div className={theme.tableBg + ` w-full flex flex-wrap relitive border rounded-b-md border-0 pt-2 `}>
                                        <div className='w-1/2 text-left pr-2   justify-between'>
                                            <p className={" text-sm font-medium  "} >Search Patient</p>
                                            <input
                                                type="text"
                                                id="0"
                                                name='patient'
                                                className={theme.cheqColor + ' shadow border w-full rounded rounded-md  py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '}
                                                placeholder="Search Patient/Couple"
                                                value={searchText.patient}
                                                onChange={(e) => handleSearchTextChange(e)}
                                                onKeyDown={(e) => handleSearchTextChange(e)}
                                            />
                                        </div>

                                        {searchPerson && searchText.id_ == 0 && (
                                            <ul className={`${theme.thumb} ${theme.track} ${theme.thin} absolute top-[100px] left-4 bg-white  mt-1 shadow-md max-h-48 w-[50%]  overflow-y-scroll scrollbar scrollbar-thin`}>
                                                {searchPerson?.map((item, index) => (
                                                    <li key={index}
                                                        className="px-2 py-1 hover:bg-gray-200 cursor-pointer text-gray-700 w-full text-xss "
                                                        onClick={() => { handleSearchResultSelect(item, 0) }}>
                                                        {/* {item?.PatientLastName + ', ' + item.PatientFirstName + ', ' + item.PatientDOB + ', ' + item.Couplecode + ', ' + item.UserName + ', '} */}
                                                        {[
                                                            item?.PatientLastName,
                                                            item?.PatientFirstName,
                                                            getCorrectDate(item?.PatientDOB),
                                                            item?.couplecode,
                                                            item?.UserName
                                                        ].filter(Boolean).join(', ')}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>

                                    {/* =========================== */}
                                    <div className={theme.tableBg + ` w-full flex flex-wrap  border rounded-b-md border-0 pt-2 `}>
                                        <div className='w-1/2 text-left pr-2   justify-between'>
                                            <p className={" text-sm font-medium  "} >First Name</p>
                                            <input type='text' name='firstname'
                                                onChange={(e) => handleInputOne(e, '1')}
                                                // disabled={isexist ? true : false}
                                                value={personData.firstname}
                                                className={theme.cheqColor + ' shadow border w-full rounded rounded-md  py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none ' + (errors.firstname ? ' border-red-400 ' : ' border-gray-300')} />
                                            {errors.firstname && <span className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{errors.firstname}</span>}
                                            {/* {isexist && <span className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">First name already exist </span>} */}

                                        </div>

                                        <div className='w-1/2 text-left  pr-2   justify-between'>

                                            <p className={" text-sm font-medium  "} >Last Name</p>
                                            <input type='text' name='surname'
                                                onChange={(e) => handleInputOne(e, '1')}
                                                value={personData.surname}
                                                className={theme.cheqColor + ' shadow border w-full rounded rounded-md py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none ' + (errors.surname ? ' border-red-400 ' : ' border-gray-300')} />

                                            {errors.surname && <span className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{errors.surname}</span>}
                                            {/* {isexist && <span className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">Surname already exist!</span>} */}

                                        </div>
                                        <div className='w-1/2 text-left  pr-2  pt-2 justify-between'>

                                            <p className={" text-sm font-medium  "} >Middle Name</p>
                                            <input type='text' name='middlename'
                                                onChange={(e) => handleInputOne(e, '1')}
                                                value={personData.middlename}
                                                className={theme.cheqColor + ' shadow border w-full rounded rounded-md py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                        </div>
                                        <div className='w-1/2 text-left  pr-2  pt-2   justify-between'>


                                            <p className={"text-sm font-medium   "} >Date of Birth</p>
                                            <input type='date' name='dob'
                                                onChange={(e) => handleInputOne(e, '1')}
                                                defaultValue={getFormattedDateForInput(personData.dob)}
                                                className={theme.cheqColor + ' shadow border w-full rounded rounded-md  py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none ' + (errors.dob ? ' border-red-400 ' : ' border-gray-300')} />
                                            {errors.dob && <span className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{errors.dob}</span>}

                                        </div>
                                        <div className='w-1/2 text-left  pr-2  pt-2   justify-between'>

                                            <p className={"text-sm font-medium "}>Email</p>
                                            <input type="email"
                                                name="email"
                                                onChange={(e) => handleInputOne(e, '1')}
                                                value={personData.email}
                                                className={theme.cheqColor + ' shadow border w-full rounded rounded-md  py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />

                                            {errors.email && <div className="error bg-red-100 font-bold text-sm text-red-500 rounded mt-1 py-1 px-2 border border-red-500">{errors.email}</div>}

                                        </div>

                                        <div className='w-1/2 text-left   pr-2  pt-2  justify-between'>
                                            <p className={" text-sm font-medium  "} >Gender</p>
                                            <div className={`pl-3  flex flex-wrap gap-x-8  py-2`} >
                                                <p className={" text-sm font-medium"} >Born As</p>
                                                <div className='flex gap-x-4'>
                                                    <label className="flex items-center ">
                                                        <input
                                                            type="radio"
                                                            name="personsex"
                                                            value="2"
                                                            checked={personData.personsex == 2}
                                                            onChange={(e) => handleInputOne(e, '1')}
                                                        />
                                                        {/* <input type="radio" className={theme.cheqColor + ' border-0 '} name='bornAs' value="Female" checked={personData.personsex === 'Female'} onClick={(e) => handleInputChange(e)} /> */}
                                                        <span className=" ml-2 text-sm  text-gray-800 dark:text-gray-600">Female</span>
                                                    </label>
                                                    <label className="flex items-center">
                                                        <input
                                                            type="radio"
                                                            name="personsex"
                                                            value='1'
                                                            checked={personData.personsex == 1}
                                                            onChange={(e) => handleInputOne(e, '1')}
                                                        />
                                                        {/* <input type="radio" className={theme.cheqColor + ' border-0 '} name='bornAs' value="Male" checked={personData.personsex === 'Male'} onClick={(e) => handleInputChange(e)} /> */}
                                                        <span className=" cursor-pointer text-sm  ml-2  text-gray-700 ">Male</span>
                                                    </label>
                                                </div>
                                            </div>
                                            {errors.personsex && <div className="error bg-red-100 font-bold text-sm text-red-500 rounded pb-0.5 py-1 px-2 border border-red-500">{errors.personsex}</div>}


                                        </div>

                                        <div className='w-1/2 text-left  pr-2  pt-2   justify-between'>
                                            {isexist &&
                                                <>
                                                    <p className={" text-sm font-medium  "} >Medicare</p>
                                                    <input type='text' name='medicarenumber'
                                                        onChange={(e) => handleInputOne(e, 1)}
                                                        // disabled={isexist ? true : false}

                                                        value={personData?.medicarenumber}
                                                        className={theme.cheqColor + 'disabled shadow border w-full rounded rounded-md  py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none ' + (errors.medicarenumber ? ' border-red-400 ' : ' border-gray-300')} />
                                                    {/* {errors.medicarenumber && <div className="error bg-red-100 font-bold text-sm text-red-500 rounded mt-1 py-1 px-2 border border-red-500">{errors.medicarenumber}</div>} */}
                                                    {/* {isexist?.medicarenumber === personData.medicarenumber && personData.medicarenumber != null && isexist?.medicarenumber != null && < p className='bg-red-100 py-1 px-2 text-red-500 rounded'>Medicare Number t </p>} */}
                                                </>
                                            }
                                        </div>



                                    </div>
                                </form>

                            }
                            <div className={'flex flex-wrap  w-full mt-2'}>

                                {personData && (personData.personid > 0) &&
                                    <div className={theme.thumb + ' ' + theme.track + ` bg-white relative  rounded-b--md shadow  overflow-y-auto  xl:w-1/2 w-full  scrollbar-thin h-[96%]`} >
                                        <div className='w-full   pb-2 font-medium bg-white  px-0.5 '>
                                            <div className={' flex justify-between text-white text-left px-2 py-1 bg-[#5599ae]  rounded-t'}> <p className=' font-medium'>User Information</p><p>Person Id : {personData?.personid || personData?.PersonID}</p></div>
                                            <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                                <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>First name</p>
                                                <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{personData?.firstname}</p>
                                            </div>
                                            <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                                <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>Last name</p>
                                                <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{personData?.surname}</p>
                                            </div>
                                            <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                                <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>dob</p>
                                                <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{getCorrectDate(personData?.dob)}</p>
                                            </div>
                                            <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                                <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>Sex at birth
                                                </p>
                                                <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{personData?.personsex || personData?.Gender === "F" ? "Female" : "Male"}</p>
                                            </div>
                                            <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                                <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>Medicarenumber</p>
                                                <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{personData?.medicarenumber}</p>
                                            </div>
                                        </div>
                                    </div>}

                                {(personData?.personid > 0) && (
                                    <div className={theme.thumb + ' ' + theme.track + ' bg-white  rounded-b--md shadow overflow-y-auto xl:w-1/2 w-full scrollbar-thin rounded-md'}>
                                        <div className={'flex justify-between text-white text-left px-2 py-1 bg-[#5599ae] rounded-t'}>
                                            <p className='font-medium'>Relationship Information</p>
                                        </div>

                                        {isexist?.coupleD || personData.couplecode ? (
                                            <div className={theme.tableBg + ' flex p-2 mt-0.5 rounded-md shadow'}>
                                                <div className="flex justify-center items-center flex-col">
                                                    <svg width="40" height="36" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.07143 8.00558C7.11335 8.00558 8.1126 7.59168 8.84935 6.85493C9.5861 6.11818 10 5.11893 10 4.07701C10 3.03509 9.5861 2.03584 8.84935 1.29909C8.1126 0.56234 7.11335 0.148438 6.07143 0.148438C5.02951 0.148438 4.03026 0.56234 3.29351 1.29909C2.55676 2.03584 2.14286 3.03509 2.14286 4.07701C2.14286 5.11893 2.55676 6.11818 3.29351 6.85493C4.03026 7.59168 5.02951 8.00558 6.07143 8.00558ZM15 8.00558C15.7578 8.00558 16.4845 7.70456 17.0203 7.16874C17.5561 6.63293 17.8571 5.9062 17.8571 5.14844C17.8571 4.39068 17.5561 3.66395 17.0203 3.12813C16.4845 2.59231 15.7578 2.29129 15 2.29129C14.2422 2.29129 13.5155 2.59231 12.9797 3.12813C12.4439 3.66395 12.1429 4.39068 12.1429 5.14844C12.1429 5.9062 12.4439 6.63293 12.9797 7.16874C13.5155 7.70456 14.2422 8.00558 15 8.00558ZM2.14286 9.43415C1.57454 9.43415 1.02949 9.65992 0.627628 10.0618C0.225765 10.4636 0 11.0087 0 11.577V11.6842C0 11.6842 0 15.8627 6.07143 15.8627C12.1429 15.8627 12.1429 11.6842 12.1429 11.6842V11.577C12.1429 11.0087 11.9171 10.4636 11.5152 10.0618C11.1134 9.65992 10.5683 9.43415 10 9.43415H2.14286ZM15 14.4342C13.96 14.4342 13.1371 14.2927 12.485 14.0677C12.7936 13.5677 12.9707 13.0856 13.0707 12.6963C13.1436 12.4165 13.1898 12.1305 13.2086 11.842C13.21 11.8134 13.2114 11.7877 13.2114 11.7656L13.2121 11.7356V11.577C13.2134 10.7863 12.9221 10.023 12.3943 9.43415H18.2807C18.5065 9.43406 18.73 9.47844 18.9386 9.56477C19.1472 9.65109 19.3367 9.77767 19.4964 9.93726C19.656 10.0969 19.7827 10.2863 19.8691 10.4949C19.9555 10.7034 20 10.927 20 11.1527C20 11.1527 20 14.4342 15 14.4342Z" fill={theme.iconColor} />
                                                    </svg>
                                                </div>
                                                <div className="flex-auto pl-1">

                                                    <p className={"cursor-pointer text-sm font-bold text-gray-700"}>{personData?.coupleD.PatientFirstName + ',' + personData?.coupleD.PatientLastName}</p>
                                                    <p className={"cursor-pointer text-sm font-bold text-gray-500"}>{personData?.coupleD.PartnerLastName + ',' + personData?.coupleD.PartnerFirstName}</p>
                                                    <p className={"cursor-pointer text-sm font-bold"}> Cp Code # <span className='text-red-800'>{personData?.coupleD?.couplecode}</span></p>
                                                </div>
                                                <div className="flex-auto pl-1">
                                                    <p className={"cursor-pointer text-xss font-bold text-gray-700"}><span>DOB : </span> {getCorrectDate(personData?.coupleD?.PatientDOB)}</p>
                                                    <p className={"cursor-pointer text-xss font-bold text-gray-700"}><span>DOB : </span> {getCorrectDate(personData?.coupleD.PartnerDOB)}</p>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="p-2 mt-0.5 rounded-md shadow bg-red-100 text-red-800">
                                                <p className="text-sm font-bold">Not in a Relationship </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={theme.thumb + ' ' + theme.track + ` h-full bg-gray-50 relative  rounded shadow  overflow-y-auto w-1/2 p-2 scrollbar-thin `} >
                        <div className=' font-medium bg-white   bg-gray-100 '>
                            <div className={' flex justify-between text-white text-left px-2 py-1 bg-[#5599ae]  rounded-t'}>
                                <p className=' font-medium'>Partner Information</p>
                                <div className=" flex gap-x-2 items-center cursor-pointer ">
                                    <input
                                        type="checkbox"
                                        id="singlePerson"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        className={theme.cheqColor + ' border focus:outline-none'}
                                    />
                                    <label htmlFor="noPartner" className="  text-sm text-white font-medium" >Tick if patient has '<span className=' text-sm text-white border-b-[2px] border-b-amber-500 font-bold'>NO PARTNER</span> '</label>
                                </div>
                            </div>

                            {!secondPerson ?
                                <div className='flex justify-center flex-col items-center h-52'>

                                    {/* <div className="flex items-center mt-1">
                                        <input
                                            type="checkbox"
                                            id="singlePerson"
                                            name="singlePerson"
                                            className={theme.cheqColor + " mr-1 mt-3"}
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label htmlFor="singlePerson" className="text-sm mt-3">Single Person</label>
                                    </div> */}
                                    <svg
                                        onClick={!isChecked ? handleSecondPerson : null}
                                        className={`cursor-pointer ${isChecked ? "opacity-50 cursor-not-allowed" : "hover:scale-105 shadow duration-500"}`}
                                        width="61" height="61" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M45.3333 0H5.66667C4.16377 0 2.72243 0.597022 1.65973 1.65973C0.597022 2.72243 0 4.16377 0 5.66667V45.3333C0 46.8362 0.597022 48.2776 1.65973 49.3403C2.72243 50.403 4.16377 51 5.66667 51H45.3333C48.45 51 51 48.45 51 45.3333V5.66667C51 2.55 48.45 0 45.3333 0ZM36.8333 28.3333H28.3333V36.8333C28.3333 38.3917 27.0583 39.6667 25.5 39.6667C23.9417 39.6667 22.6667 38.3917 22.6667 36.8333V28.3333H14.1667C12.6083 28.3333 11.3333 27.0583 11.3333 25.5C11.3333 23.9417 12.6083 22.6667 14.1667 22.6667H22.6667V14.1667C22.6667 12.6083 23.9417 11.3333 25.5 11.3333C27.0583 11.3333 28.3333 12.6083 28.3333 14.1667V22.6667H36.8333C38.3917 22.6667 39.6667 23.9417 39.6667 25.5C39.6667 27.0583 38.3917 28.3333 36.8333 28.3333Z"
                                            fill={isChecked ? "#9898987a" : theme.iconColor}
                                        />
                                    </svg>
                                </div>
                                :
                                < form className={theme.tableBg + ` w-full flex flex-wrap p-2 border  rounded-b-md`}>
                                    {secondPersonPData?.isPersonExist > 0 && <div id="alert-4" className="flex justify-center w-full self-end shadow p-2 mb-1 mr-0 text-[#9b1c1c] border rounded-lg bg-red-100 dark:text-red-300" role="alert">
                                        <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#9b1c1c" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                        </svg>
                                        <span className="sr-only">Info</span>
                                        <div className="ms-3 text-sm font-medium text-[#9b1c1c]">
                                            <i> ~Partner Already Exist ~</i>
                                        </div>
                                    </div>}

                                    {/* =========================== */}
                                    <div className={theme.tableBg + ` w-full flex flex-wrap relitive border rounded-b-md border-0 pt-2 `}>
                                        <div className='w-1/2 text-left pr-2   justify-between'>
                                            <p className={" text-sm font-medium  "} >Search Partner</p>
                                            <input
                                                type="text"
                                                id="1"
                                                name='partner'
                                                className={theme.cheqColor + ' shadow border w-full rounded rounded-md  py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '}
                                                placeholder="Search Partner/Couple"
                                                value={searchText.partner}
                                                onChange={(e) => handleSearchTextChange(e)}
                                                onKeyDown={(e) => handleSearchTextChange(e)}
                                            />
                                        </div>

                                        {searchPerson && searchText.id_ == 1 && (
                                            <ul className={`${theme.thumb} ${theme.track} ${theme.thin} absolute top-[140px] left-4 rounded bg-white  mt-1 shadow-md max-h-48 w-[50%]  overflow-y-scroll scrollbar scrollbar-thin`}>
                                                {searchPerson?.map((item, index) => (
                                                    <li
                                                        key={index}
                                                        className="px-2 py-1 hover:bg-gray-200 cursor-pointer text-gray-700 w-full text-xss"

                                                        onClick={() => { handleSearchResultSelect(item, 1) }}
                                                    >
                                                        {/* {item?.PartnerLastName + ', ' + item.PartnerFirstName + ', ' + item.PartnerDOB + ', ' + (item.couplecode || "") + ', ' + (item.UserName || "") + ', '} */}
                                                        {[
                                                            item?.PartnerLastName,
                                                            item?.PartnerFirstName,
                                                            getCorrectDate(item?.PartnerDOB),
                                                            item?.couplecode,
                                                            item?.UserName
                                                        ].filter(Boolean).join(', ')}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>


                                    {/* =========================== */}

                                    <div className={theme.tableBg + ` w-full flex flex-wrap  border rounded-b-md border-0 pt-2 `}>
                                        <div className='w-1/2 text-left pr-2   justify-between'>
                                            <p className={" text-sm font-medium  "} >First Name</p>
                                            <input type='text' name='firstname'
                                                onChange={(e) => handleInputChange(e, 2)}
                                                // disabled={secondPersonIsexist ? true : false}
                                                value={secondPersonPData.firstname}

                                                // className={theme.cheqColor + ' shadow border w-full rounded rounded-md  py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none ' + (errorsP2.firstname || secondPersonIsexist?.firstname ? ' border-red-400 ' : ' border-gray-300')} />
                                                className={theme.cheqColor + ' shadow border w-full rounded rounded-md  py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none ' + (errorsP2.firstname ? ' border-red-400 ' : ' border-gray-300')} />
                                            {errorsP2.firstname && <span className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{errorsP2.firstname}</span>}
                                            {/* {secondPersonIsexist && <span className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">First name already exist </span>} */}
                                        </div>

                                        <div className='w-1/2 text-left  pr-2  justify-between'>

                                            <p className={"text-sm font-medium  "} >Last Name</p>
                                            <input type='text' name='surname'
                                                onChange={(e) => handleInputChange(e, 2)}
                                                // disabled={secondPersonIsexist ? true : false}
                                                value={secondPersonPData.surname}
                                                className={theme.cheqColor + ' shadow border w-full rounded rounded-md  py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none ' + (errorsP2.surname ? ' border-red-400 ' : ' border-gray-300')} />

                                            {errorsP2.surname && <span className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{errorsP2.surname}</span>}

                                        </div>
                                        <div className='w-1/2 text-left  pr-2   pt-2 justify-between'>

                                            <p className={"text-sm font-medium  "} >Middle Name</p>
                                            <input type='text' name='middlename'
                                                onChange={(e) => handleInputChange(e, 2)}
                                                value={secondPersonPData.middlename}
                                                className={theme.cheqColor + ' shadow border w-full rounded rounded-md py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                            {/* {errorsP2.surname && <div className="error">{errorsP2.surname}</div>} */}
                                            {/* {errorsP2.middlename && <div className="error bg-red-100 font-bold text-sm text-red-600 rounded mt-1 py-1 px-2 border border-red-500">{errorsP2.middlename}</div>} */}

                                        </div>
                                        <div className='w-1/2 text-left  pr-2  pt-2   justify-between'>

                                            <p className={"text-sm font-medium  "} >Date of Birth</p>
                                            <input type='date' name='dob'
                                                onChange={(e) => handleInputChange(e, 2)}
                                                defaultValue={getFormattedDateForInput(secondPersonPData.dob)}
                                                // defaultValue={getFormattedDateForInput(secondPersonPData.dob)}
                                                className={theme.cheqColor + 'shadow border w-full rounded rounded-md  py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none ' + (errorsP2.dob ? ' border-red-400 ' : ' border-gray-300')} />
                                            {errorsP2.dob && <span className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{errorsP2.dob}</span>}

                                        </div>
                                        <div className='w-1/2 text-left  pr-2  pt-2   justify-between'>

                                            <p className={"text-sm font-medium  "} >Email</p>
                                            <input type="email"
                                                name="email"
                                                onChange={(e) => handleInputChange(e, 2)}
                                                value={secondPersonPData.email}
                                                className={theme.cheqColor + 'shadow border w-full rounded rounded-md  py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />

                                            {/* {errorsP2.email && <div className="error bg-red-100 font-bold text-sm text-red-500 rounded mt-1 py-1 px-2 border border-red-500">{errorsP2.email}</div>} */}

                                        </div>

                                        <div className='w-1/2 text-left   pr-2  pt-2  justify-between'>
                                            <p className={" text-sm font-medium  "} >Gender</p>
                                            <div className={`pl-3  flex flex-wrap gap-x-8  py-2`} >
                                                <p className={" text-sm font-medium"} >Born As</p>
                                                <div className='flex gap-x-4'>
                                                    <label className="flex items-center ">
                                                        <input
                                                            type="radio"
                                                            name="personsex"
                                                            value="1"
                                                            checked={secondPersonPData.personsex == 1}
                                                            onChange={handleInputChange}
                                                        />
                                                        {/* <input type="radio" className={theme.cheqColor + ' border-0 '} name='bornAs' value="Female" checked={secondPersonPData.personsex === 'Female'} onClick={(e) => handleInputChange(e)} /> */}
                                                        <span className=" ml-2 text-sm  text-gray-800 dark:text-gray-600">Female</span>
                                                    </label>
                                                    <label className="flex items-center">
                                                        <input
                                                            type="radio"
                                                            name="personsex"
                                                            value="2"
                                                            checked={secondPersonPData.personsex == 2}
                                                            onChange={handleInputChange}
                                                        />
                                                        {/* <input type="radio" className={theme.cheqColor + ' border-0 '} name='bornAs' value="Male" checked={secondPersonPData.personsex === 'Male'} onClick={(e) => handleInputChange(e)} /> */}
                                                        <span className=" cursor-pointer text-sm  ml-2  text-gray-700 ">Male</span>
                                                    </label>
                                                </div>
                                            </div>
                                            {errorsP2.personsex && <div className="error bg-red-100 font-bold text-sm text-red-500 rounded pb-0.5 py-1 px-2 border border-red-500">{errorsP2.personsex}</div>}

                                        </div>
                                        <div className='w-1/2 text-left  pr-2  pt-2   justify-between'>
                                            {secondPersonIsexist &&
                                                <>
                                                    <p className={" text-sm font-medium  "} >Medicare</p>
                                                    <input type='text' name='medicarenumber'
                                                        onChange={(e) => handleInputChange(e, 2)}
                                                        value={secondPersonPData?.medicarenumber}
                                                        className={theme.cheqColor + 'disabled shadow border w-full rounded rounded-md  py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                                </>
                                            }
                                        </div>
                                    </div>

                                </form>
                            }

                            <div className={'flex flex-wrap  w-full mt-2'}>
                                {secondPersonPData?.personid > 0 && <div className={theme.thumb + ' ' + theme.track + ` bg-white relative  rounded-b--md shadow  overflow-y-auto xl:w-1/2 w-full   scrollbar-thin h-[96%]`} >
                                    <div className='w-full   pb-2 font-medium bg-white  px-0.5 '>
                                        <div className={' flex justify-between text-white text-left px-2 py-1 bg-[#5599ae]  rounded-t'}> <p className=' font-medium'>User Information </p><p>Person Id 11: {secondPersonPData?.personid || secondPersonPData?.PersonID}</p></div>
                                        <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>First name</p>
                                            <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{secondPersonPData?.firstname}</p>
                                        </div>
                                        <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>Last name</p>
                                            <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{secondPersonPData?.surname}</p>
                                        </div>
                                        <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>dob</p>
                                            <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{getCorrectDate(secondPersonPData?.dob)}</p>
                                        </div>


                                        <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>Sex at birth
                                            </p>
                                            <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{secondPersonPData?.personsex || secondPersonPData?.Gender === "F" ? "Female" : "Male"}</p>
                                        </div>
                                        <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>Medicarenumber</p>
                                            <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{secondPersonPData?.medicarenumber}</p>
                                        </div>

                                    </div>
                                </div>}

                                {secondPersonPData?.personid > 0 && (
                                    <div className={theme.thumb + ' ' + theme.track + ' bg-white relative rounded-b--md shadow overflow-y-auto xl:w-1/2 w-full scrollbar-thin rounded-md'}>
                                        <div className={'flex justify-between text-white text-left px-2 py-1 bg-[#5599ae] rounded-t'}>
                                            <p className='font-medium'>Relationship Information</p>
                                        </div>

                                        {secondPersonPData?.coupleD || secondPersonPData.couplecode ? (
                                            <div className={theme.tableBg + ' flex p-2 mt-0.5 rounded-md shadow'}>
                                                <div className="flex justify-center items-center flex-col">
                                                    <svg width="40" height="36" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.07143 8.00558C7.11335 8.00558 8.1126 7.59168 8.84935 6.85493C9.5861 6.11818 10 5.11893 10 4.07701C10 3.03509 9.5861 2.03584 8.84935 1.29909C8.1126 0.56234 7.11335 0.148438 6.07143 0.148438C5.02951 0.148438 4.03026 0.56234 3.29351 1.29909C2.55676 2.03584 2.14286 3.03509 2.14286 4.07701C2.14286 5.11893 2.55676 6.11818 3.29351 6.85493C4.03026 7.59168 5.02951 8.00558 6.07143 8.00558ZM15 8.00558C15.7578 8.00558 16.4845 7.70456 17.0203 7.16874C17.5561 6.63293 17.8571 5.9062 17.8571 5.14844C17.8571 4.39068 17.5561 3.66395 17.0203 3.12813C16.4845 2.59231 15.7578 2.29129 15 2.29129C14.2422 2.29129 13.5155 2.59231 12.9797 3.12813C12.4439 3.66395 12.1429 4.39068 12.1429 5.14844C12.1429 5.9062 12.4439 6.63293 12.9797 7.16874C13.5155 7.70456 14.2422 8.00558 15 8.00558ZM2.14286 9.43415C1.57454 9.43415 1.02949 9.65992 0.627628 10.0618C0.225765 10.4636 0 11.0087 0 11.577V11.6842C0 11.6842 0 15.8627 6.07143 15.8627C12.1429 15.8627 12.1429 11.6842 12.1429 11.6842V11.577C12.1429 11.0087 11.9171 10.4636 11.5152 10.0618C11.1134 9.65992 10.5683 9.43415 10 9.43415H2.14286ZM15 14.4342C13.96 14.4342 13.1371 14.2927 12.485 14.0677C12.7936 13.5677 12.9707 13.0856 13.0707 12.6963C13.1436 12.4165 13.1898 12.1305 13.2086 11.842C13.21 11.8134 13.2114 11.7877 13.2114 11.7656L13.2121 11.7356V11.577C13.2134 10.7863 12.9221 10.023 12.3943 9.43415H18.2807C18.5065 9.43406 18.73 9.47844 18.9386 9.56477C19.1472 9.65109 19.3367 9.77767 19.4964 9.93726C19.656 10.0969 19.7827 10.2863 19.8691 10.4949C19.9555 10.7034 20 10.927 20 11.1527C20 11.1527 20 14.4342 15 14.4342Z" fill={theme.iconColor} />
                                                    </svg>
                                                </div>
                                                <div className="flex-auto pl-1">

                                                    <p className={"cursor-pointer text-sm font-bold text-gray-700"}>{secondPersonPData?.coupleD?.PatientFirstName + ',' + secondPersonPData?.coupleD?.PatientLastName}</p>
                                                    <p className={"cursor-pointer text-sm font-bold text-gray-500"}>{secondPersonPData?.coupleD?.PartnerLastName + ',' + secondPersonPData?.coupleD?.PartnerFirstName}</p>
                                                    <p className={"cursor-pointer text-sm font-bold"}> Cp Code # <span className='text-red-800'>{secondPersonPData?.coupleD?.couplecode}</span></p>
                                                </div>
                                                <div className="flex-auto pl-1">
                                                    <p className={"cursor-pointer text-xss font-bold text-gray-700"}><span>DOB : </span> {getCorrectDate(secondPersonPData?.coupleD?.PatientDOB)}</p>
                                                    <p className={"cursor-pointer text-xss font-bold text-gray-700"}><span>DOB : </span> {getCorrectDate(secondPersonPData?.coupleD?.PartnerDOB)}</p>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="p-2 mt-0.5 rounded-md shadow bg-red-100 text-red-800">
                                                <p className="text-sm font-bold">Not in a Relationship.</p>
                                            </div>
                                        )}
                                    </div>
                                )
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full py-2 rounded flex justify-end items-center  px-6'>
                    <button className="bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded"
                        onClick={(e) => handleCancelFirst(e)}
                    > Cancel  </button>
                    <button className="bg-transparent hover:bg-green-800 text-green-700 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent mr-3 rounded"
                        onClick={(e) => handetcheq(e)}> Add </button>
                    {/* {!secondPersonIsexist ? <button className="bg-transparent hover:bg-green-800 text-green-700 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent mr-3 rounded"
                        onClick={(e) => handetcheq(e)}> Add </button>
                        :
                        <button className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded"
                        onClick={(e) => handelRelation(e)}
                        >  Confirm</button>
                    } */}

                </div>
            </div >
            {ShowModal &&
                <div className="fixed z-50 inset-0 overflow-y-auto " >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div onClick={() => { setShowModal(false) }} style={{ backdropFilter: 'blur(7px)' }} className="fixed inset-0 transition-opacity filter" aria-hidden="true" >
                            <div className="absolute inset-0 bg-gray-700 opacity-70  " ></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl " aria-hidden="true" ></span>
                        {/* <Draggable> */}
                        <div className={`sm:my-8   sm:max-w-lg sm:w-full filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`}
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline"
                        >

                            <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative  `}>
                                <svg className={' absolute top-2 right-2 cursor-pointer shadow  font-semibold  '}
                                    onClick={() => { setShowModal(false); }}
                                    width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
                                </svg>
                                <h3 className={theme.txt_color + " text-xl text-center mb-6 leading-6 font-bold text-gray-900"} id="modal-headline" > Do you want to create relationship? </h3>
                                {/* ?.personid > 0 || personData.Couplecode */}

                                {(personData?.personid > 0 || personData?.personid) && (
                                    <div className={theme.thumb + ' ' + theme.track + ' bg-white relative rounded-b--md shadow overflow-y-auto w-full scrollbar-thin rounded-md'}>
                                        <div className={'flex justify-between text-white text-left px-2 py-1 bg-[#5599ae] rounded-t'}>
                                            <p className='font-medium'>Patient Relationship Information</p>
                                        </div>

                                        {isexist?.coupleD ? (
                                            <div className={theme.tableBg + ' flex p-2 mt-0.5 rounded-md shadow'}>
                                                <div className="flex justify-center items-center flex-col">
                                                    <svg width="40" height="36" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.07143 8.00558C7.11335 8.00558 8.1126 7.59168 8.84935 6.85493C9.5861 6.11818 10 5.11893 10 4.07701C10 3.03509 9.5861 2.03584 8.84935 1.29909C8.1126 0.56234 7.11335 0.148438 6.07143 0.148438C5.02951 0.148438 4.03026 0.56234 3.29351 1.29909C2.55676 2.03584 2.14286 3.03509 2.14286 4.07701C2.14286 5.11893 2.55676 6.11818 3.29351 6.85493C4.03026 7.59168 5.02951 8.00558 6.07143 8.00558ZM15 8.00558C15.7578 8.00558 16.4845 7.70456 17.0203 7.16874C17.5561 6.63293 17.8571 5.9062 17.8571 5.14844C17.8571 4.39068 17.5561 3.66395 17.0203 3.12813C16.4845 2.59231 15.7578 2.29129 15 2.29129C14.2422 2.29129 13.5155 2.59231 12.9797 3.12813C12.4439 3.66395 12.1429 4.39068 12.1429 5.14844C12.1429 5.9062 12.4439 6.63293 12.9797 7.16874C13.5155 7.70456 14.2422 8.00558 15 8.00558ZM2.14286 9.43415C1.57454 9.43415 1.02949 9.65992 0.627628 10.0618C0.225765 10.4636 0 11.0087 0 11.577V11.6842C0 11.6842 0 15.8627 6.07143 15.8627C12.1429 15.8627 12.1429 11.6842 12.1429 11.6842V11.577C12.1429 11.0087 11.9171 10.4636 11.5152 10.0618C11.1134 9.65992 10.5683 9.43415 10 9.43415H2.14286ZM15 14.4342C13.96 14.4342 13.1371 14.2927 12.485 14.0677C12.7936 13.5677 12.9707 13.0856 13.0707 12.6963C13.1436 12.4165 13.1898 12.1305 13.2086 11.842C13.21 11.8134 13.2114 11.7877 13.2114 11.7656L13.2121 11.7356V11.577C13.2134 10.7863 12.9221 10.023 12.3943 9.43415H18.2807C18.5065 9.43406 18.73 9.47844 18.9386 9.56477C19.1472 9.65109 19.3367 9.77767 19.4964 9.93726C19.656 10.0969 19.7827 10.2863 19.8691 10.4949C19.9555 10.7034 20 10.927 20 11.1527C20 11.1527 20 14.4342 15 14.4342Z" fill={theme.iconColor} />
                                                    </svg>
                                                </div>
                                                <div className="flex-auto pl-1">
                                                    <p className={"cursor-pointer text-sm font-bold text-gray-700"}>{isexist?.coupleD?.PatientFullName}</p>
                                                    <p className={"cursor-pointer text-sm font-bold text-gray-500"}>{isexist?.coupleD?.PartnerFullName}</p>
                                                    <p className={"cursor-pointer text-sm font-bold"}>Cp Code # <span className='text-red-800'>{personData?.coupleD.CoupleCode}</span></p>
                                                </div>
                                                <div className="flex-auto pl-1">
                                                    <p className={"cursor-pointer text-xss font-bold text-gray-700"}><span>DOB :  </span> {getCorrectDate(personData?.coupleD?.PatientDOB)}</p>
                                                    <p className={"cursor-pointer text-xss font-bold text-gray-700"}><span>DOB :  </span> {getCorrectDate(personData?.coupleD?.PartnerDOB)}</p>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="p-2 mt-0.5 rounded-md shadow bg-red-100 text-red-800">
                                                <p className="text-sm font-bold">Does not exist!</p>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {(secondPersonPData?.personid || secondPersonPData?.personid) && (
                                    <div className={theme.thumb + ' ' + theme.track + ' bg-white relative rounded-b--md my-2 shadow overflow-y-auto w-full scrollbar-thin rounded-md'}>
                                        <div className={'flex justify-between text-white text-left px-2 py-1 bg-[#5599ae] rounded-t'}>
                                            <p className='font-medium'>Partner Relationship Information</p>
                                        </div>

                                        {secondPersonPData?.coupleD ? (
                                            <div className={theme.tableBg + ' flex p-2 mt-0.5 rounded-md shadow'}>
                                                <div className="flex justify-center items-center flex-col">
                                                    <svg width="40" height="36" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.07143 8.00558C7.11335 8.00558 8.1126 7.59168 8.84935 6.85493C9.5861 6.11818 10 5.11893 10 4.07701C10 3.03509 9.5861 2.03584 8.84935 1.29909C8.1126 0.56234 7.11335 0.148438 6.07143 0.148438C5.02951 0.148438 4.03026 0.56234 3.29351 1.29909C2.55676 2.03584 2.14286 3.03509 2.14286 4.07701C2.14286 5.11893 2.55676 6.11818 3.29351 6.85493C4.03026 7.59168 5.02951 8.00558 6.07143 8.00558ZM15 8.00558C15.7578 8.00558 16.4845 7.70456 17.0203 7.16874C17.5561 6.63293 17.8571 5.9062 17.8571 5.14844C17.8571 4.39068 17.5561 3.66395 17.0203 3.12813C16.4845 2.59231 15.7578 2.29129 15 2.29129C14.2422 2.29129 13.5155 2.59231 12.9797 3.12813C12.4439 3.66395 12.1429 4.39068 12.1429 5.14844C12.1429 5.9062 12.4439 6.63293 12.9797 7.16874C13.5155 7.70456 14.2422 8.00558 15 8.00558ZM2.14286 9.43415C1.57454 9.43415 1.02949 9.65992 0.627628 10.0618C0.225765 10.4636 0 11.0087 0 11.577V11.6842C0 11.6842 0 15.8627 6.07143 15.8627C12.1429 15.8627 12.1429 11.6842 12.1429 11.6842V11.577C12.1429 11.0087 11.9171 10.4636 11.5152 10.0618C11.1134 9.65992 10.5683 9.43415 10 9.43415H2.14286ZM15 14.4342C13.96 14.4342 13.1371 14.2927 12.485 14.0677C12.7936 13.5677 12.9707 13.0856 13.0707 12.6963C13.1436 12.4165 13.1898 12.1305 13.2086 11.842C13.21 11.8134 13.2114 11.7877 13.2114 11.7656L13.2121 11.7356V11.577C13.2134 10.7863 12.9221 10.023 12.3943 9.43415H18.2807C18.5065 9.43406 18.73 9.47844 18.9386 9.56477C19.1472 9.65109 19.3367 9.77767 19.4964 9.93726C19.656 10.0969 19.7827 10.2863 19.8691 10.4949C19.9555 10.7034 20 10.927 20 11.1527C20 11.1527 20 14.4342 15 14.4342Z" fill={theme.iconColor} />
                                                    </svg>
                                                </div>
                                                <div className="flex-auto pl-1">

                                                    <p className={"cursor-pointer text-sm font-bold text-gray-700"}>{secondPersonPData?.coupleD?.PatientFullName}</p>
                                                    <p className={"cursor-pointer text-sm font-bold text-gray-500"}>{secondPersonPData?.coupleD?.PartnerFullName}</p>
                                                    <p className={"cursor-pointer text-sm font-bold"}> Cp Code # <span className='text-red-800'>{secondPersonPData?.coupleD?.CoupleCode}</span></p>
                                                </div>
                                                <div className="flex-auto pl-1">
                                                    <p className={"cursor-pointer text-xss font-bold text-gray-700"}><span>dob </span> {secondPersonPData?.coupleD?.PatientDOB}</p>
                                                    <p className={"cursor-pointer text-xss font-bold text-gray-700"}><span>dob </span> {secondPersonPData?.coupleD?.PartnerDOB}</p>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="p-2 mt-0.5 rounded-md shadow bg-red-100 text-red-800">
                                                <p className="text-sm font-bold">Does not exist!</p>
                                            </div>
                                        )}
                                    </div>
                                )}

                                <div className='w-full py-2 rounded flex justify-end items-center  px-6'>
                                    <button className="bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded"
                                        onClick={(e) => { setShowModal(false) }}
                                    > Cancel  </button>
                                    <button className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded"
                                        onClick={(e) => handelRelation(e)}
                                    >  Confirm</button>


                                </div>


                            </div>
                        </div>
                        {/* </Draggable> */}

                    </div>
                </div>
            }
        </div >
    )
}

const mapStateToProps = (state) => ({
    loading: state.loading,
})

const mapDispatchToProps = { ApiCall_GET, ApiCall_POST }

export default connect(mapStateToProps, mapDispatchToProps)(CreatePerson)
