import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { TaskTypes, calculateDifference, formatTime, generateScanInput, getDateByRange, getFormattedDateForInput } from '../../../../../../../common';
import { ApiCall_GET } from '../../../../../../../Redux/Generic/action';
import { getCorrectDate } from '../../../../../../../common';
import UpdateInCycleScan from './UpdateInCycleScan';
import DonorDetails from './DonorDetails';
import AddDayOne from './AddDayOne';
import UpdateInCycleBlood from './UpdateInCycleBlood';
import Anzard from './Anzard';
import { useAppContext } from '../../../../../../../AppContextProvider ';
import NursingCheckList from './NursingCheckList';

export const SubTaskDetails = ({ ApiCall_GET, props }) => {
    var artId = props.artid
    var rowData = props.rowData;
    const theme = useAppContext().themeDetails;
    // const [formData, setFormData] = useState();
    const [scanList, setScanList] = useState();
    const [stimMed, setStimMed] = useState('');
    // const [lutealMed, setLutealMed] = useState('');
    // const [triggerMed, setTriggerMed] = useState('');
    const [adjuvantMed, setAdjuvantMed] = useState('');

    const handleMedication = (str) => {
        if (!str) return '';
        const substrings = str.replaceAll('=>', ' ').split(',').map(substring => substring.trim());
        return substrings;
    };

    const getDaysFromMedications = (str) => {
        const substrings = str.split(',');
        var rtnArr = [];
        substrings.map((substring) => {
            var days = substring.split('Day - =>')[1] ?? '';
            if (days === '') {
                var startDate = substring.split('Start=>')[1] ?? '';
                startDate = startDate.split('=>')[0];
                rtnArr.push(startDate);
                var finishDate = substring.split('Finish=>')[1] ?? '';
                finishDate = finishDate.split('=>')[0];
                rtnArr.push(finishDate);
                // var finishDate = substring.split('Finish=>')[1];
                // rtnArr.push(finishDate);
            }
            else {
                if (days.split(' ').length > 0) {
                    rtnArr.push(days.split('=>')[0])
                }
                else {
                    rtnArr.push(days);
                }
            }
        });
        return rtnArr;
    };

    const getDistinctDaysFromMedications = () => {
        var array = getDaysFromMedications(stimMed).concat(getDaysFromMedications(adjuvantMed));
        const uniqueNumbersArray = [...new Set(array)];
        const sortedArrayAsc = uniqueNumbersArray?.slice()?.sort((a, b) => a - b);
        return sortedArrayAsc;
    }

    const getMedicationArr = (str) => {
        const substrings = str.replaceAll('=>', ' ')?.split(',').map(substring => substring.split('When')[0].trim());
        return substrings;
    };

    var subTaskDetails = props.subTaskDetailList;
    var task = {
        isDonating: 43,
        isRecieving: 44
    }

    useEffect(() => {

        var apiUrl = '';
        if (rowData.TaskId === TaskTypes.TreatmentPlanCreated || rowData.TaskId === TaskTypes.TreatmentPlanRevised) {
            apiUrl = 'Procedures/LoadMedicationByTreatmentplanId/' + rowData.TreatmentPlanId;
        }
        if (rowData.SubTaskId === TaskTypes.ScanInformation) {
            apiUrl = 'Art/GetScanDetails/' + artId;
        }
        else if (rowData.SubTaskId === TaskTypes.PregnancyScanTest) {
            apiUrl = 'Art/GetPregScanTestDetails/' + artId;
        }
        else if (rowData.SubTaskId === TaskTypes.BloodsInformation) {
            apiUrl = 'Art/GetBloodTestDetails/' + artId;

        }
        else if (rowData.SubTaskId === TaskTypes.DonorInformation) {
            apiUrl = 'Art/GetBloodTestDetails/' + artId;
            return;
        }
        else if (rowData.TaskId === TaskTypes.AnzardData) {
            // alert("test")
            // apiUrl = 'Art/GetBloodTestDetails/' + artId;
            // return;
        }

        apiUrl && ApiCall_GET(apiUrl, true).then(function (payload) {
            if (rowData.TaskId === TaskTypes.TreatmentPlanCreated || rowData.TaskId === TaskTypes.TreatmentPlanRevised) {
                setAdjuvantMed(payload.AdjuvantMedication);
                setStimMed(payload.StimMedication);
                // setLutealMed(payload.LuthealMedication)
                // setTriggerMed(payload.TriggerMedication)
            }
            else if (rowData.SubTaskId === TaskTypes.BloodsInformation || rowData.SubTaskId === TaskTypes.DonorInformation) {
                // setFormData(payload[0]);
                setScanList(payload);
            } else {
                // setFormData(payload.ScanDetailList[0]);
                setScanList(payload.ScanDetailList);
            }
        });
    }, []);

    return (
        <div className={" shadow mb-2 rounded-md border  "}>
            {/* Cycle Summary */}
            {rowData.SubTaskId === TaskTypes.AssessmentPage ? (
                <div className={theme.tableBg + 'w-full  border-r bg-yellow-50 border-gray-300 p-2'}>
                    <p className='text-sm font-bold'>{'>'} Cycle Summary</p>
                    <div className={' flex mt-1'}>
                        <div style={{ width: '20%' }} className='  h-full  p-2  '>
                            <div className='bg-white px-2 py-1 text-xss border shadow my-1 rounded-md  flex items-center'>
                                <p className='text-sm font-medium mr-2'>Eggs Collected: </p>
                                <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.OocyteCol}</p>
                            </div>
                            <div className='bg-white px-2 py-1 text-xss border shadow my-1 rounded-md  flex items-center'>
                                <p className='text-sm font-medium mr-2'>Eggs Inseminated :</p>
                                <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.EggsInsem}</p>
                            </div>
                        </div>
                        <div style={{ width: '40%' }} className='  h-full flex flex-wrap items-center justify-between border-x border-gray-300 p-2  '>
                            <div style={{ width: '49%' }} className='bg-white px-2 py-1 text-xss border shadow mt-1 rounded-md flex items-center'>
                                <p className='text-sm font-medium mr-2'>Immature Eggs : </p>
                                <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.Immatureeggs}</p>
                            </div>
                            <div style={{ width: '49%' }} className='bg-white px-2 py-1 text-xss border shadow mt-1 rounded-md flex items-center'>
                                <p className='text-sm font-medium mr-2'>Degenerate Eggs :</p>
                                <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.DegenerateEggs}</p>
                            </div>
                            <div style={{ width: '49%' }} className='bg-white px-2 py-1 text-xss border shadow mt-1 rounded-md  flex items-center'>
                                <p className='text-sm font-medium mr-2'>Abnormal Eggs :</p>
                                <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.Abnormaleggs}</p>
                            </div>
                            <div style={{ width: '49%' }} className='bg-white px-2 py-1 text-xss border shadow mt-1 rounded-md flex items-center'>
                                <p className='text-sm font-medium mr-2'>Transfer Eggs : </p>
                                <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.Trans}</p>
                            </div>
                            <div style={{ width: '49%' }} className='bg-white px-2 py-1 text-xss border shadow mt-1 rounded-md flex items-center'>
                                <p className='text-sm font-medium mr-2'>Post ICSI Deg Eggs :</p>
                                <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.PosticsiDegeneratEeggs}</p>
                            </div>
                        </div>
                        <div style={{ width: '20%' }} className='   h-full   p-2 '>
                            <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md  flex items-center'>
                                <p className='text-sm font-medium mr-2'>1PN Fertilisation: </p>
                                <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.Fertr1pn}</p>
                            </div>
                            <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md  flex items-center'>
                                <p className='text-sm font-medium mr-2'>2PN Fertilisation :</p>
                                <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.Fertr2pn}</p>
                            </div>
                            <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md  flex items-center'>
                                <p className='text-sm font-medium mr-2'>3PN Fertilisation :</p>
                                <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.Fertr3pn}</p>
                            </div>
                        </div>
                        <div style={{ width: '20%' }} className='   h-full  border-l border-gray-300 p-2 '>
                            <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md  flex items-center'>
                                <p className='text-sm font-medium mr-2'>Semen Source: </p>
                                <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.SemenSource}</p>
                            </div>
                            <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md  flex items-center'>
                                <p className='text-sm font-medium mr-2'>Semen Type :</p>
                                <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.SemenSourceType}</p>
                            </div>
                            <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md flex items-center'>
                                <p className='text-sm font-medium mr-2'>Insem. Type :</p>
                                <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.SemenSourceInsemType}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
                :
                rowData.SubTaskId === TaskTypes.SpermFreezeResults ? (
                    <div className='w-full flex'>
                        <div style={{ width: '55%' }} className={theme.tableBg + '  border-r border-gray-300 bg-yellow-50 p-2'}>
                            <p className='text-sm font-bold'>{'>'} Sperm Freeze Analysis</p>
                            <div className={' flex mt-1'}>
                                <div style={{ width: '33%' }} className=' h-full   pr-5 '>
                                    <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>Freeze Method : </p>
                                        <p className='text-sm '>{subTaskDetails.SpermFreAnalysis.SfFreezeMethod}</p>
                                    </div>
                                    <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>No. Straws Frozen :</p>
                                        <p className='text-sm '>{subTaskDetails.SpermFreAnalysis.SfStrawsFrozen}</p>
                                    </div>
                                </div>
                                <div style={{ width: '33%' }} className=' h-full   pr-5 '>
                                    <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>Storage Tank : </p>
                                        <p className='text-sm '>{subTaskDetails.SpermFreAnalysis.StorageTankName}</p>
                                    </div>
                                    <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>Storage Position :</p>
                                        <p className='text-sm '>{subTaskDetails.SpermFreAnalysis.TpPositionName}</p>
                                    </div>
                                </div>
                                <div style={{ width: '33%' }} className=' h-full   pr-5 '>
                                    <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>Goblet Colour : </p>
                                        <p className='text-sm '>{subTaskDetails.SpermFreAnalysis.SfGoblitColour}</p>
                                    </div>
                                    <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>Other :</p>
                                        <p className='text-sm '>{subTaskDetails.SpermFreAnalysis.SfFreezeMethodOption}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div style={{ width: '45%' }} className={theme.tableBg + '  border-r border-gray-300 p-2'}>
                            {/* <label className={' font-bold text- xl  ' + ' border-b-[3px] border-b-amber-500 ml-3 mt-3 pb-1'}></label> */}
                            <p className='text-sm font-bold'>{'>'} Post Freeze Analysis</p>
                            <div className={' flex mt-1'}>
                                <div style={{ width: '50%' }} className=' h-full   pr-5 '>
                                    <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>Volume : </p>
                                        <p className='text-sm '>{subTaskDetails.SpermFreAnalysis.SfVolume}</p>
                                    </div>
                                    <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>Count :</p>
                                        <p className='text-sm '>{subTaskDetails.SpermFreAnalysis.SfCount}</p>
                                    </div>
                                </div>
                                <div style={{ width: '50%' }} className=' h-full   pr-5 '>
                                    <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>Motility : </p>
                                        <p className='text-sm '>{subTaskDetails.SpermFreAnalysis.SfMotility}</p>
                                    </div>
                                    <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>Grade 3 :</p>
                                        <p className='text-sm '>{subTaskDetails.SpermFreAnalysis.SaGrade3}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                    :
                    rowData.SubTaskId === TaskTypes.FreezeResults ? (
                        <div style={{ width: '100%' }} className={theme.tableBg + '   p-2'}>
                            <p className='text-sm font-bold'>{'>'} Freeze Summary</p>
                            <div className={' flex mt-1'}>
                                <div style={{ width: '25%' }} className=' h-full   pr-5 '>
                                    <p className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>Freeze Date : </p>
                                        <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.FreezeDate}</p>
                                    </p>
                                    <p className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>Freeze Type :</p>
                                        <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.FreezeType}</p>
                                    </p>
                                </div>
                                <div style={{ width: '25%' }} className=' h-full   pr-5 '>
                                    <p className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>Freeze Stage : </p>
                                        <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.FreezeStage}</p>
                                    </p>
                                    <p className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>Frozen :</p>
                                        <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.NumFrozen}</p>
                                    </p>
                                </div>
                                <div style={{ width: '25%' }} className=' h-full   pr-5 '>
                                    <p className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>Freeze Time : </p>
                                        <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.FreezeTime}</p>
                                    </p>
                                    <p className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>Storage Tank :</p>
                                        <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.StorageTankName}</p>
                                    </p>
                                </div>
                                <div style={{ width: '25%' }} className=' h-full   pr-5 '>
                                    <p className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>Storage Location : </p>
                                        <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.StorageLocation}</p>
                                    </p>
                                    <p className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md w-full flex items-center'>
                                        <p className='text-sm font-medium mr-2'>Goblet Colour :</p>
                                        <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.Goblet}</p>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                        :
                        rowData.SubTaskId === TaskTypes.TransferResults || rowData.SubTaskId === TaskTypes.IUIResults ? (
                            <div className={theme.tableBg + ' w-full  border-r border-gray-300 p-2'}>
                                {/* <label className={' font-bold text- xl  ml-3 mt-3 pb-1'}>Transfer Summary</label> */}
                                <p className={theme.txt_color + ' text-sm font-bold  '}>{'>'} Transfer Summary</p>
                                <div className={' flex '}>
                                    <div style={{ width: '25%' }} className='  h-full  p-2  '>
                                        <div className='bg-white px-2 py-1 text-xss border shadow my-1 rounded-md  flex items-center'>
                                            <p className='text-sm font-medium mr-2'>Date : </p>
                                            <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.EmbroDate}</p>
                                        </div>
                                        <div className='bg-white px-2 py-1 text-xss border shadow my-1 rounded-md  flex items-center'>
                                            <p className='text-sm font-medium mr-2'>Time :</p>
                                            <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.EmbroTime}</p>
                                        </div>
                                    </div>
                                    <div style={{ width: '25%' }} className='  h-full  items-center justify-between border-x border-gray-300 p-2  '>
                                        <div className='bg-white px-2 py-1 text-xss border shadow mt-1 rounded-md flex items-center'>
                                            <p className='text-sm font-medium mr-2'>Clinician : </p>
                                            <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.ClinicianName}</p>
                                        </div>
                                        <div className='bg-white px-2 py-1 text-xss border shadow mt-1 rounded-md flex items-center'>
                                            <p className='text-sm font-medium mr-2'>Scientist :</p>
                                            <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.ScientistName}</p>
                                        </div>
                                    </div>
                                    <div style={{ width: '25%' }} className='   h-full   p-2 '>
                                        <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md  flex items-center'>
                                            <p className='text-sm font-medium mr-2'>Comment : </p>
                                            <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.EmbroETComment}</p>
                                        </div>
                                        <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md  flex items-center'>
                                            <p className='text-sm font-medium mr-2'># Transferred  :</p>
                                            <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.Scientist}</p>
                                        </div>
                                    </div>
                                    <div style={{ width: '25%' }} className='   h-full  border-l border-gray-300 p-2 '>
                                        <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md  flex items-center'>
                                            <p className='text-sm font-medium mr-2'>Blood : </p>
                                            <input id="checked-checkbox" type="checkbox" disabled={true} defaultChecked={subTaskDetails.FreezeCycleTransferSummary.EmbBlood} className={theme.cheqColor + " w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"} />
                                            <p className='text-xss ml-3'>{subTaskDetails.FreezeCycleTransferSummary.EmbroBloodLevel}</p>
                                            {/* <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.SemenSource}</p> */}
                                        </div>
                                        <div className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-md  flex items-center'>
                                            <p className='text-sm font-medium mr-2'>Mucous :</p>
                                            <input id="checked-checkbox" type="checkbox" disabled={true} defaultChecked={subTaskDetails.FreezeCycleTransferSummary.EmbMucous} className={theme.cheqColor + " w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"} />
                                            <p className='text-xss ml-3'>{subTaskDetails.FreezeCycleTransferSummary.EmbroMucousLevel}</p>
                                            {/* <p className='text-sm '>{subTaskDetails.FreezeCycleTransferSummary.SemenSourceType}</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) :
                            false ? (
                                <div className={theme.tableBg + ' w-full flex py-2'}>
                                    <div style={{ width: '25%' }} className={'  border-r border-gray-300 p-2'}>
                                        <p className='text-sm font-bold'>{'>'} Dates</p>
                                        <div className={'  mt-1 justify-between w-full  flex flex-wrap'}>
                                            <div style={{ width: '49%' }} className='bg-white px-2 py-1 text-xss border shadow mt-1 rounded-md w-full flex items-center'>
                                                <p className='text-sm font-medium mr-2'>ET Date : </p>
                                                <p className='text-sm '>{subTaskDetails.PregScan.ETDate}</p>
                                            </div>
                                            <div style={{ width: '49%' }} className='bg-white px-2 py-1 text-xss border shadow mt-1 rounded-md w-full flex items-center'>
                                                <p className='text-sm font-medium mr-2'>EDD Date :</p>
                                                <p className='text-sm '>{subTaskDetails.PregScan.EDDdate}</p>
                                            </div>
                                            <div style={{ width: '49%' }} className='bg-white px-2 py-1 text-xss border shadow mt-1 rounded-md w-full flex items-center'>
                                                <p className='text-sm font-medium mr-2'>FET Date :</p>
                                                <p className='text-sm '>{subTaskDetails.PregScan?.FETDate}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: '25%' }} className={'  border-r border-gray-300 p-2'}>
                                        <p className='text-sm font-bold'>{'>'} Scan Results</p>
                                        <div className={'  mt-1 justify-between w-full  flex flex-wrap'}>
                                            <div style={{ width: '49%' }} className='bg-white px-2 py-1 text-xss border shadow mt-1 rounded-md w-full flex items-center'>
                                                <p className='text-sm font-medium mr-2'>Fetal Heart : </p>
                                                <p className='text-sm '>{subTaskDetails.PregScan.FetalHearts}</p>
                                            </div>
                                            <div style={{ width: '49%' }} className='bg-white px-2 py-1 text-xss border shadow mt-1 rounded-md w-full flex items-center'>
                                                <p className='text-sm font-medium mr-2'>Fetal Sacs :</p>
                                                <p className='text-sm '>{subTaskDetails.PregScan.FetalSacs}</p>
                                            </div>
                                            <div style={{ width: '49%' }} className='bg-white px-2 py-1 text-xss border shadow mt-1 rounded-md w-full flex items-center'>
                                                <p className='text-sm font-medium mr-2'>Heart Rate : </p>
                                                <p className='text-sm '>{subTaskDetails.PregScan.HeartRate}</p>
                                            </div>
                                            <div style={{ width: '49%' }} className='bg-white px-2 py-1 text-xss border shadow mt-1 rounded-md w-full flex items-center'>
                                                <p className='text-sm font-medium mr-2'>CRL :</p>
                                                <p className='text-sm '>{subTaskDetails.PregScan.Crl}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ width: '25%' }} className={'  border-r border-gray-300 p-2'}>
                                        <p className='text-sm font-bold'>{'>'} Embryo Development Stage Days</p>
                                        <div className={'  mt-1 justify-between w-full  flex flex-wrap'}>
                                            <div style={{ width: '100%' }} className='bg-white px-2 py-1 text-xss border shadow mt-1 rounded-md w-full flex items-center'>
                                                <p className='text-sm font-medium mr-2'>Embryo Development Stage Days : </p>
                                                <p className='text-sm '>{subTaskDetails.PregScan.EmbryoDevelopmentStage}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: '25%' }} className={'  border-r border-gray-300 p-2'}>
                                        <p className='text-sm font-bold'>{'>'} Comments</p>
                                        <div className={'  mt-1 justify-between w-full  flex flex-wrap'}>
                                            <div style={{ width: '100%' }} className='bg-white px-2 py-1 text-xss border shadow mt-1 rounded-md w-full flex items-center '>
                                                {/* <p className='text-sm font-medium mr-2'>Embryo Development Stage Days : </p> */}
                                                {subTaskDetails ?
                                                    <p className='text-sm '>--</p>
                                                    :
                                                    <p className='text-sm '>{subTaskDetails.PregScan.Comments}</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) :
                                rowData.TaskId === TaskTypes.StartFSHMedication ? (
                                    <div className={theme.tableBg + '   p-2'}>
                                        <p className='text-sm font-medium'>{'>'} Medication</p>
                                        <div className='w-full  flex flex-wrap'>
                                            {/* {getMedicationArr(rowData.Medications).map((item) => (
                                                item.length > 0 ? <p className='bg-white px-2 py-1 text-xss border shadow m-1 rounded-full'>{item}</p> : ''
                                            ))} */}
                                        </div>
                                    </div>
                                ) :
                                    rowData.TaskId === TaskTypes.Day1Date ? (
                                        <AddDayOne rowData={rowData} theme={theme} isDone={rowData.Status} artId={artId} />
                                    )
                                        :
                                        rowData.TaskId === TaskTypes.TreatmentPlanRevised || rowData.TaskId === TaskTypes.TreatmentPlanCreated ? (
                                            <div onDoubleClick={(e) => e.stopPropagation()} className={theme.thumb + ' ' + theme.track + ` bg-yellow-50   rounded-md shadow  overflow-y-auto scroll scrollbar-thin `} >
                                                <div className='flex items-center my-2 '>
                                                    <div className='w-1/5'>
                                                        <p className='text-sm flex items-center justify-center font-bold'>Day Numbers</p>
                                                    </div>
                                                    <div className='w-2/5'>
                                                        <p className='text-sm font-bold'>Stimulation Medications</p>
                                                    </div>
                                                    <div className='w-2/5 flex items-center justify-between'>
                                                        <p className='text-sm font-bold'>Adjuvant Therapy Medications</p>
                                                        {/* <div onClick={() => props.showDetails(rowData.TaskId)} className='bg-white shadow m-1 rounded-full flex items-center cursor-pointer'>
                                                        <p className='px-2 py-1 text-xss rounded-full'>View Full Treatment Plan</p>
                                                    </div> */}
                                                    </div>
                                                </div>
                                                {getDistinctDaysFromMedications().map((item) => (item.length > 0 ?
                                                    <div className='w-full border-b border-gray-200  my-1'>
                                                        <div className='flex items-center'>
                                                            <div className='w-1/5 flex justify-center'>
                                                                <div className={theme.txt_color + '   rounded minWidthDay ' + theme.borderColor}>
                                                                    <div className={'w-full h-3 border-b text-center  text-white text-xs7  ' + theme.tHedUpdte + ' '}>DAY</div>
                                                                    <div className={'w-full h-4 text-center text-sm font-bold bg-white rounded '}>{item}</div>
                                                                    {rowData.DayOneofCycleDate ? (
                                                                        <div className={'w-full h-3 text-center text-xs7 px-1 font-bold bg-white rounded '}>{getDateByRange(rowData.DayOneofCycleDate, item)}</div>
                                                                    ) : ''}
                                                                </div>
                                                                {/* 
                                                            <div className={theme.txt_color + ' w-8 h-8  rounded ' + theme.borderColor}>
                                                                <div className={'w-full h-3 border-b text-center  text-white text-xs7  ' + theme.tHedUpdte + ' '}>DAY</div>
                                                                <div className={'w-full h-4 text-center text-sm font-bold bg-white rounded '}>{item}</div>
                                                            </div> */}
                                                            </div>
                                                            <div className='w-2/5'>
                                                                <div className=' mb-2 flex items-center flex-wrap'>
                                                                    {stimMed && handleMedication(stimMed).filter(x => x.includes("Day -  " + item)).map((innerItem) => (
                                                                        item.length > 0 ?
                                                                            <p className='bg-white px-2 py-1 text-xss cursor-pointer hover:scale-110 shadow m-1 rounded-full'>
                                                                                {innerItem.split('When')[0]}
                                                                            </p>
                                                                            : ''
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className='w-2/5'>
                                                                <div className=' mb-2 flex items-center flex-wrap'>
                                                                    {adjuvantMed && handleMedication(adjuvantMed).filter(x => x.includes("Start " + item) || x.includes("Finish " + item)).map((innerItem) => (
                                                                        item.length > 0 ?
                                                                            (innerItem.includes("Start " + item) ?
                                                                                <div className='bg-white shadow m-1 cursor-pointer hover:scale-110 rounded-full flex items-center'>
                                                                                    <p className='px-2 py-1 text-xss rounded-l-full'>{innerItem.split("Start ")[0]}</p>
                                                                                    <p className='bg-green-300  font-medium px-2 py-1 text-xss rounded-r-full'>Start</p>
                                                                                </div>
                                                                                :
                                                                                <div className='bg-white shadow m-1 cursor-pointer hover:scale-110 rounded-full flex items-center'>
                                                                                    <p className='px-2 py-1 text-xss rounded-l-full'>{innerItem.split("Start ")[0]}</p>
                                                                                    <p className='bg-red-300   font-medium px-2 py-1 text-xss rounded-r-full'>Finish</p>
                                                                                </div>) : ''
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    : ''
                                                ))}
                                            </div>

                                        ) :
                                            rowData.SubTaskId === TaskTypes.ScanInformation ? (
                                                (
                                                    // !scanList?.filter(x => x.scanresultid > 0 && x.scanresultid === rowData.PregnancyBloodScanId)[0] ? <p className='bg-white text-red-500 px-2 py-1 text-sm text-center  rounded'>~ No data to show ~</p> :
                                                    <div onDoubleClick={(e) => e.stopPropagation()} className={theme.thumb + ' ' + theme.track + ` bg-yellow-50 px-1 my-1 py-1 m-auto w-[99%] rounded-md shadow  overflow-y-auto scroll scrollbar-thin `} >
                                                        {/* {formData?.TreatmentPlanId && formData?.DayOneDate ? */}
                                                        <label className={' font-bold cursor-pointer ' + theme.txt_color + '  pt-1 ml-1 text-white  border-b-[3px] border-b-amber-500'}>Scan Results2</label>
                                                        {scanList && (rowData.PregnancyBloodScanId ?
                                                            scanList?.filter(x => x.scanresultid === rowData.PregnancyBloodScanId).map((item, index) => (
                                                                <div key={index} className={theme.panelBg + ' xl:flex   rounded rounded '}>
                                                                    {/* left--- */}
                                                                    <div className={theme.thumb + ' ' + theme.track + ' rounded overflow-auto scrollbar  scrollbar-thin w-full px-2 py-1 '} style={{ width: "100%", }}>
                                                                        <div className={' bg-[#a9cbd6a6] w-full  flex  mt-1  rounded-t py-1 text-center '}>
                                                                            <div style={{ width: '5%', height: "100% " }} className={' flex item-center justify-center  py-1 '}>
                                                                                <p className='text-sm font-medium '>Day </p>
                                                                            </div>
                                                                            <div style={{ width: '15%', height: "100% " }} className={' py-1 text-center '}>
                                                                                <p className='text-sm font-medium '>Day One of Cycle</p>
                                                                            </div>
                                                                            <div style={{ width: '10%', height: "100% " }} className={'  py-1 text-center'}>
                                                                                <p className='text-sm font-medium '>Scan Date</p>
                                                                            </div>
                                                                            <div style={{ width: '15%', height: "100% " }} className={'  py-1text-center '}>
                                                                                <p className='text-sm font-medium '>Endometrial Thicknes</p>
                                                                            </div>
                                                                            <div style={{ width: '15%', height: "100% " }} className={'  py-1text-center '}>
                                                                                <p className='text-sm font-medium '>Next Scan Date</p>
                                                                            </div>
                                                                            <div style={{ width: '20%', height: "100% " }} className={'  py-1 text-center'}>
                                                                                <p className='text-sm font-medium '>Uterus</p>
                                                                            </div>
                                                                            <div style={{ width: '20%', height: "100% " }} className={'  py-1 text-center'}>
                                                                                <p className='text-sm font-medium '>Notes/Comments</p>
                                                                            </div>

                                                                        </div>

                                                                        < div className={' bg-white   w-full  flex    rounded-b shadow border'} >
                                                                            <div style={{ width: '5%', height: "100% " }} className={' flex item-center justify-center  text-center border-r py-2'}>
                                                                                <p className='text-sm text-gray-700 '>{item.scandayofcycle ? item.scandayofcycle : <span className='text-sm text-red-400 font-medium '>--</span>}</p>
                                                                            </div>
                                                                            <div style={{ width: '15%', height: "100% " }} className='border-r py-2 text-center'>
                                                                                <p className='text-sm  text-gray-700'>{item.testdayofcycle}</p>
                                                                            </div>
                                                                            <div style={{ width: '10%', height: "100% " }} className='border-r py-2 text-center' >
                                                                                <p className='text-sm text-gray-700 '>{getCorrectDate(item?.scandate).split('-').reverse().join('-')}</p>
                                                                            </div>
                                                                            <div style={{ width: '15%', height: "100% " }} className='border-r py-2 text-center' >
                                                                                <p className='text-sm text-gray-700 '>{item.result_endo}</p>
                                                                            </div>
                                                                            <div style={{ width: '15%', height: "100% " }} className='border-r py-2 text-center' >
                                                                                <p className='text-sm text-gray-700 '>{getFormattedDateForInput(item?.nextscan)}</p>
                                                                            </div>
                                                                            <div style={{ width: '20%', height: "100% " }} className='border-r py-2 text-center' >
                                                                                <p className='text-sm text-gray-700 '>{item.result_uterus}</p>
                                                                            </div>
                                                                            <div style={{ width: '20%', height: "100% " }} className='border-r py-2 text-center' >
                                                                                <p className='text-sm text-gray-700 '>{item.comments}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* Right--- */}
                                                                    <div className='px-2 pr-0 mt-0.5  '>
                                                                        <div className={theme.txt_color + "  items-center  text-sm  flex font-bold "} >
                                                                            <label className={" mr-2  text-sm  font-bold w-8 "} >Size : </label>
                                                                            {Array.from({ length: 16 }).map((_, i) => (
                                                                                // <p id={`l_${i + 10}`} name='result_readonly' className={'h-5  w-8 text-center mx-1  text-sm text-gray-700 '}>{(23 - i) === 23 ? ('>' + (23 - i)) : (23 - i) === 10 ? ('<' + (23 - i)) : (23 - i).toString()}</p>
                                                                                <p id={`l_${i + 10}`} name='result_readonly' className={'w-8 h-5  text-center mt-1 mx-1   text-sm text-gray-700 '}>{(i + 9) === 9 ? '<' + (i + 10) : (i + 9) === 24 ? ('>' + (i + 8)) : (i + 9)}</p>
                                                                            ))}
                                                                        </div>

                                                                        <div className={' flex  items-center  '}>
                                                                            <label className={" text-sm mr-2  w-8"} >L</label>
                                                                            {Array.from({ length: 16 }).map((_, i) => (
                                                                                <input
                                                                                    disabled={true}
                                                                                    value={generateScanInput(item.result_l)?.split(',').reverse()[i]}
                                                                                    className={'w-8 h-5 bg-white text-center mt-1 mx-1 shadow border rounded rounded  text-sm text-gray-700 '}
                                                                                />
                                                                            ))}
                                                                        </div>
                                                                        <div className='flex items-center '>
                                                                            <label className={" mr-2  text-sm  font-bold w-8 "} >R</label>
                                                                            {Array.from({ length: 16 }).map((_, i) => (
                                                                                <input
                                                                                    disabled={true}
                                                                                    value={generateScanInput(item.result_r)?.split(',').reverse()[i]}
                                                                                    className={'w-8 h-5 bg-white text-center mt-1 mx-1 shadow border rounded rounded  text-sm text-gray-700 '}
                                                                                />
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                            : (rowData.DayOneofCycleDate ?
                                                                <UpdateInCycleScan scanList={scanList} theme={theme} />
                                                                : <p className='bg-white text-red-500 px-2 py-1 text-sm text-center  rounded'>~ Add day one of cycle ~</p>
                                                            ))
                                                        }
                                                    </div>
                                                )
                                            ) :
                                                rowData.SubTaskId === TaskTypes.PregnancyScanTest ? (
                                                    (!scanList?.filter(x => x.scanresultid > 0 && x.scanresultid === rowData.PregnancyBloodScanId)[0] ? <p className='bg-white text-red-500 px-2 py-1 text-sm text-center  rounded'>~ No data to show ~</p> :
                                                        <div className={theme.thumb + ' ' + theme.track + ` bg-gray-50 p-1 rounded-md shadow  overflow-y-auto scroll scrollbar-thin height-80`} >
                                                            {/* {formData?.TreatmentPlanId && formData?.DayOneDate ? */}
                                                            {scanList?.filter(x => x.scanresultid === rowData.PregnancyBloodScanId).map((item, index) => (
                                                                <div key={index} className={" rounded py-0  bg-yellow-50 "}>
                                                                    <div className={theme.panelBg + '  pl-4 shadow rounded'}>
                                                                        <label className={' font-bold cursor-pointer ' + theme.txt_color + '  pt-1 ml-1 text-white  border-b-[3px] border-b-amber-500'}>Pregnancy Scan Results</label>
                                                                        {/* <label className={' font-medium underline cursor-pointer ' + theme.txt_color + ' pt-1 ml-1  '}>Pregnancy Scan Results</label> */}
                                                                        <div className='w-full '>
                                                                            <div className='flex mt-2 p-2'>
                                                                                <div style={{ width: '20%' }} className=''>
                                                                                    <div className=' text-left p-1  flex '>
                                                                                        <p className='cursor-pointer text-sm font-bold w-36 text-gray-700'>Day One of Cycle</p>
                                                                                        <p className='cursor-pointer text-sm  text-gray-700 flex items-center'>{item.dayoneofcycle}</p>
                                                                                    </div>
                                                                                    <div className=' text-left p-1  flex '>
                                                                                        <p className='cursor-pointer text-sm font-bold w-36 text-gray-700'>Scan Date </p>
                                                                                        <p className='cursor-pointer text-sm  text-gray-700 flex items-center'>{getCorrectDate(item.scandate)}</p>
                                                                                    </div>
                                                                                    {/* <div className=' text-left p-1  flex '>
                                                                                    <p className='cursor-pointer text-sm font-bold w-44 text-gray-700'>Cycle Day</p>
                                                                                    <p className='cursor-pointer text-sm  text-gray-700 flex items-center'>{item.scandayofcycle}</p>
                                                                                </div> */}
                                                                                    <div className=' text-left p-1  flex '>
                                                                                        <p className='cursor-pointer text-sm font-bold w-36 text-gray-700'>Next Pregnancy Scan</p>
                                                                                        <p className='cursor-pointer text-sm  text-gray-700 flex items-center'>{getCorrectDate(item?.nextscan)} {formatTime(item?.nextscan, true)}</p>
                                                                                    </div>
                                                                                    <div className=' text-left p-1  flex '>
                                                                                        <p className='cursor-pointer text-sm font-bold w-36 text-gray-700'>Scan Performed By</p>
                                                                                        <p className='cursor-pointer text-sm  text-gray-700 flex items-center'>{item.clinicianname}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ width: '15%' }} className=''>
                                                                                    <div className=' text-left p-1  flex '>
                                                                                        <p className='cursor-pointer text-sm font-bold w-24 text-gray-700'>EPU Date </p>
                                                                                        <p className='cursor-pointer text-sm  text-gray-700 flex items-center'>{getCorrectDate(item.dateofEPU)}</p>
                                                                                    </div>
                                                                                    <div className=' text-left p-1  flex '>
                                                                                        <p className='cursor-pointer text-sm font-bold w-24 text-gray-700'>FET Date </p>
                                                                                        <p className='cursor-pointer text-sm  text-gray-700 flex items-center'>{getCorrectDate(item.fetdate)}</p>
                                                                                    </div>
                                                                                    <div className=' text-left p-1  flex '>
                                                                                        <p className='cursor-pointer text-sm font-bold w-24 text-gray-700'>ET Date </p>
                                                                                        <p className='cursor-pointer text-sm  text-gray-700 flex items-center'>{getCorrectDate(item.etdate)}</p>
                                                                                    </div>
                                                                                    <div className=' text-left p-1  flex '>
                                                                                        <p className='cursor-pointer text-sm font-bold w-24 text-gray-700'>EDD Date </p>
                                                                                        <p className='cursor-pointer text-sm  text-gray-700 flex items-center'>{getCorrectDate(item.edddate)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ width: '20%' }} className=''>
                                                                                    <div className=' text-left p-1  flex '>
                                                                                        <p className='cursor-pointer text-sm font-bold w-52 text-gray-700'>Number of Fetal Hearts</p>
                                                                                        <p className='cursor-pointer text-sm  text-gray-700 flex items-center'>{item.fetalHearts > 0 ? item.fetalHearts : ''}</p>
                                                                                    </div>
                                                                                    <div className=' text-left p-1  flex '>
                                                                                        <p className='cursor-pointer text-sm font-bold w-52 text-gray-700'>Number of Intrauterine Sacs</p>
                                                                                        <p className='cursor-pointer text-sm  text-gray-700 flex items-center'>{item.fetalSacs > 0 ? item.fetalSacs : ''}</p>
                                                                                    </div>

                                                                                    <div className=' text-left p-1  flex '>
                                                                                        <p className='cursor-pointer text-sm font-bold w-44 text-gray-700'>Heart Rate</p>
                                                                                        <p className='cursor-pointer text-sm  text-gray-700 flex items-center'>{item.heartrate}</p>
                                                                                    </div>

                                                                                    <div className=' text-left p-1  flex '>
                                                                                        <p className='cursor-pointer text-sm font-bold w-44 text-gray-700'>CRL</p>
                                                                                        <p className='cursor-pointer text-sm  text-gray-700 flex items-center'>{item.crl}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ width: '20%' }} className=''>
                                                                                    <div className=' text-left p-1  flex '>
                                                                                        <p className='cursor-pointer text-sm font-bold w-52 text-gray-700'>Embryo Development Stage Days</p>
                                                                                        <p className='cursor-pointer text-sm  text-gray-700 flex items-center'>{item.embryodevelopmentstage}</p>
                                                                                    </div>
                                                                                    <div className=' text-left p-1  flex '>
                                                                                        <p className='cursor-pointer text-sm font-bold w-44 text-gray-700'>Private Hospital</p>
                                                                                        <p className='cursor-pointer text-sm  text-gray-700 flex items-center'>{item.privateHospital}</p>
                                                                                    </div>
                                                                                    <div className=' text-left p-1  flex '>
                                                                                        <p className='cursor-pointer text-sm font-bold w-44 text-gray-700'>Public Hospital</p>
                                                                                        <p className='cursor-pointer text-sm  text-gray-700 flex items-center'>{item.publicHospital}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ width: '25%' }} className=''>
                                                                                    <div className='p-2 rounded flex flex-wrap justify-start'>
                                                                                        <textarea id="message" rows="4" disabled={true} value={item.comments} className="  mt-2 w-full text-sm px-1 py-0.5 border rounded accent-green-700  text-gray-700 focus:border-teal-500 focus:outline-none" placeholder="Comments ..." />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )
                                                ) :
                                                    rowData.SubTaskId === TaskTypes.BloodsInformation ? (
                                                        (scanList && !scanList?.filter(x => x.bloodtestid > 0 && x.bloodtestid === rowData.PregnancyBloodScanId)[0] ?
                                                            (rowData.DayOneofCycleDate ?
                                                                <UpdateInCycleBlood rowData={rowData} artId={artId} scanList={scanList} theme={theme} />
                                                                : <p className='bg-white text-red-500 px-2 py-1 text-sm text-center  rounded'>~ Add day one of cycle ~</p>
                                                            )
                                                            :
                                                            <div className={theme.thumb + ' ' + theme.track + ` p-1 rounded-md shadow  overflow-y-auto scroll scrollbar-thin bg-yellow-50`} >
                                                                {/* {formData?.TreatmentPlanId && formData?.DayOneDate ? */}
                                                                {scanList?.filter(x => x.bloodtestid === rowData.PregnancyBloodScanId).map((item, index) => (
                                                                    <>
                                                                        {/* <div key={index} className={" rounded py-0   "}>
                                                                            <div className={theme.panelBg + ' pt-1 pl-4 shadow rounded'}>

                                                                                <label className={' font-bold cursor-pointer ' + theme.txt_color + '  ml-1 text-white  border-b-[3px] border-b-amber-500'}>Blood Test Results</label>

                                                                                <div className={theme.panelBg + ' flex   rounded'}>
                                                                                    <div className=' p-2 w-3/12'>
                                                                                        <div className={'p-2 flex items-center'}>
                                                                                            <p className={" text-sm mx-2  w-36"} >Day One of Cycle</p>
                                                                                            <input type="text" value={item?.dayoneofcycle} disabled={true} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                                                                        </div>
                                                                                        <div className={'p-2 flex items-center'}>
                                                                                            <label htmlFor="testdate" className={" text-sm mx-2 w-36  "} >Test Date</label>
                                                                                            <input type='date' name='testdate' disabled={true} defaultValue={getFormattedDateForInput(item?.testdate)} min={item && item?.testdate && getCorrectDate(item?.testdate).split('-').reverse().join('-')} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />

                                                                                        </div>
                                                                                        <div className={'p-2 flex items-center'}>
                                                                                            <label className={" text-sm mx-2 w-36  "} >Day of Cycle</label>
                                                                                            <input name='' disabled={true} defaultValue={item?.testdayofcycle} type='text' className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className=' p-2 w-10/12'>
                                                                                        <div className='w-full flex items-center justify-between mb-2'>
                                                                                            <div className={'text-center'}>
                                                                                                <p className={" text-sm mx-2 font-bold w-24"} >E2 pmol/L </p>
                                                                                                <input type="text" name='e2_dose' defaultValue={item?.e2_dose} disabled={true} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                                                                            </div>
                                                                                            <div className={'text-center'}>
                                                                                                <p className={" text-sm mx-2 font-bold  w-24  "} >P4 nmol/L</p>
                                                                                                <input type='text' name='p4_dose' defaultValue={item?.p4_dose} disabled={true} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                                                                            </div>
                                                                                            <div className={'text-center'}>
                                                                                                <p className={" text-sm mx-2 font-bold w-24  "} >LH IU/L</p>
                                                                                                <input type='text' name='lh_dose' defaultValue={item?.lh_dose} disabled={true} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                                                                            </div>
                                                                                            <div className={'text-center'}>
                                                                                                <p className={" text-sm mx-2 font-bold w-24  "} >FSH IU/L</p>
                                                                                                <input type='text' name='fsh_dose' defaultValue={item?.fsh_dose} disabled={true} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                                                                            </div>
                                                                                            <div className={'text-center'}>
                                                                                                <p className={" text-sm mx-2 font-bold w-24"} >HCG IU/L </p>
                                                                                                <input type="text" name='hcg_dose' defaultValue={item?.hcg_dose} disabled={true} className={theme.cheqColor + ' w-32 shadow border rounded rounded-xl px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className=' text-left pt-1  w-full mr-0.5  mb-0.5 '>
                                                                                            <p className='text-sm mb-2 font-bold w-28  '>Notes/Comments</p>
                                                                                            <textarea id="txtcomments" rows="3" defaultValue={item?.comments} disabled={true} name="comments" className="  w-full text-sm px-1 py-0.5 border rounded accent-green-700  text-gray-700 focus:border-teal-500 focus:outline-none" placeholder="Add comments ..." />
                                                                                        </div>

                                                                                    </div>
                                                                                </div>


                                                                            </div>

                                                                        </div> */}
                                                                        {/*  */}


                                                                        <div className='w-full   '>
                                                                            <div className={' bg-[#5599ae] text-white text-left p-1  rounded-t'}>
                                                                                <p className='text-sbase font-medium'>Blood Test Results</p>
                                                                            </div>
                                                                            <div className={theme.thumb + ' ' + theme.track + '   overflow-auto scrollbar  scrollbar-thin w-full px-2 py-1 '} style={{ width: "100%" }}>
                                                                                <div className={' bg-[#a9cbd6a6] w-full  flex  mt-1  rounded-t py-1 '}>
                                                                                    <div style={{ width: '5%', height: "100% " }} className={' flex item-center justify-center    text-center'}>
                                                                                        <p className='text-sm font-medium '>Day</p>
                                                                                    </div>
                                                                                    <div style={{ width: '10%', height: "100% " }} className={' flex item-center justify-center   '}>
                                                                                        <p className='text-sm font-medium '>Day One of Cycle </p>
                                                                                    </div>
                                                                                    <div style={{ width: '10%', height: "100% " }} className={'  text-center  '}>
                                                                                        <p className='text-sm font-medium '>Test Date </p>
                                                                                    </div>
                                                                                    <div style={{ width: '10%', height: "100% " }} className={'  text-center '}>
                                                                                        <p className='text-sm font-medium '>E2 pmol/L</p>
                                                                                    </div>
                                                                                    <div style={{ width: '10%', height: "100% " }} className={'   text-center'}>
                                                                                        <p className='text-sm font-medium '>P4 nmol/L</p>
                                                                                    </div>
                                                                                    <div style={{ width: '10%', height: "100% " }} className={'   text-center '}>
                                                                                        <p className='text-sm font-medium '>LH IU/L</p>
                                                                                    </div>
                                                                                    <div style={{ width: '10%', height: "100% " }} className={'  text-center '}>
                                                                                        <p className='text-sm font-medium '>FSH IU/L</p>
                                                                                    </div>
                                                                                    <div style={{ width: '10%', height: "100% " }} className={'   text-center '}>
                                                                                        <p className='text-sm font-medium '>HCG IU/L</p>
                                                                                    </div>
                                                                                    <div style={{ width: '25%', height: "100% " }} className={'   '}>
                                                                                        <p className='text-sm font-medium '>Notes/Comments</p>
                                                                                    </div>

                                                                                </div>
                                                                                < div className={' bg-white w-full  flex   rounded-b shadow border'} >
                                                                                    <div style={{ width: '5%', height: "100% " }} className={' flex item-center justify-center border-r  py-2 text-center'}>
                                                                                        <p className='text-sm text-gray-700 '>{item.testdayofcycle}</p>
                                                                                    </div>
                                                                                    <div style={{ width: '10%', height: "100% " }} className={' flex item-center justify-center border-r  py-2 text-center'}>
                                                                                        <p className='text-sm text-gray-700 '>{item.dayoneofcycle}</p>
                                                                                    </div>
                                                                                    <div style={{ width: '10%', height: "100% " }} className='border-r  py-2 text-center'>
                                                                                        <p className='text-sm  text-gray-700'>{getCorrectDate(item.testdate)}</p>
                                                                                    </div>
                                                                                    <div style={{ width: '10%', height: "100% " }} className='border-r  py-2 text-center'>
                                                                                        <p className='text-sm text-gray-700 '>{item.e2_dose}</p>
                                                                                    </div>
                                                                                    <div style={{ width: '10%', height: "100% " }} className='border-r  py-2 text-center' >
                                                                                        <p className='text-sm text-gray-700 '>{item.p4_dose}</p>
                                                                                    </div>
                                                                                    <div style={{ width: '10%', height: "100% " }} className='border-r  py-2 text-center' >
                                                                                        <p className='text-sm text-gray-700 '>{item.lh_dose}</p>
                                                                                    </div>
                                                                                    <div style={{ width: '10%', height: "100% " }} className='border-r  py-2 text-center' >
                                                                                        <p className='text-sm text-gray-700 '>{item.fsh_dose}</p>
                                                                                    </div>
                                                                                    <div style={{ width: '10%', height: "100% " }} className='  py-2 text-center' >
                                                                                        <p className='text-sm text-gray-700 '>{item.hcg_dose}</p>
                                                                                    </div>
                                                                                    <div style={{ width: '25%', height: "100% " }} className='px-2' >
                                                                                        <textarea id="txtcomments" rows="2" defaultValue={item?.comments} disabled={true} name="comments" className="  w-full text-sm px-1 mt-0.5 border rounded accent-green-700  text-gray-700 focus:border-teal-500 focus:outline-none" placeholder="Add comments ..." />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            </div>
                                                        )
                                                    )
                                                        :
                                                        rowData.SubTaskId === TaskTypes.DonorInformation ? (
                                                            <DonorDetails rowData={rowData} theme={theme} task={task} />
                                                        )
                                                            :
                                                            rowData.TaskId === TaskTypes.AnzardData ? (
                                                                <Anzard artId={artId} task={task} />
                                                            )
                                                                :
                                                                rowData.TaskId === TaskTypes.NurseCheckList ? (
                                                                    // <Anzard artId={artId} task={task} />
                                                                    <NursingCheckList rowData={rowData} artId={artId} />
                                                                )
                                                                    :
                                                                    (<p className='bg-white text-red-500 px-2 py-1 text-sm text-center  rounded'>~ No data to show ~</p>)}
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_GET }

export default connect(mapStateToProps, mapDispatchToProps)(SubTaskDetails)